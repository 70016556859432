import * as faCamera from '@fortawesome/fontawesome-free-solid/faCamera'
import * as faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle'
import * as faEdit from '@fortawesome/fontawesome-free-solid/faEdit'
import * as faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle'
import * as faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import update from 'immutability-helper'
import * as React from 'react'
import { Item, Loading } from '../views/entity'

interface Image {
	src: string
	previewSrc: string
}

interface MerchandiseItem {
	brand: string
	checked: boolean
	text: string | null
	image: Image | null
}

interface MerchandiseViewProps extends MerchandiseItem {
	isEdit: boolean
	toggle(): void
	toggleEdit(): void
	change(value: string): void
	photo(): void
	save(): void
}

const MerchandiseView: React.FunctionComponent<MerchandiseViewProps> = props => {
	const { image } = props
	return (
		<div>
			<span
				className="crm-entity-widget-client-box-position"
				style={{
					cursor: 'pointer',
					position: 'absolute',
					right: 0,
				}}
				onClick={props.photo}
			>
				<FontAwesomeIcon icon={faCamera} />
			</span>
			{image !== null && (
				<span
					className="merchandise-image"
					onClick={() => {
						window.top.postMessage(
							`CustomVisitHelper:openImage:${JSON.stringify({ url: image.src })}`,
							`https://${window.BX24.getDomain()}`
						)
					}}
				>
					<img src={image.previewSrc} />
				</span>
			)}
			<a onClick={props.toggle} className="crm-entity-widget-client-box-name">
				<FontAwesomeIcon
					icon={props.checked ? faCheckCircle : faExclamationCircle}
					style={{ color: props.checked ? 'green' : 'red', cursor: 'pointer' }}
				/>{' '}
				{props.brand}
			</a>
			<div className="crm-entity-widget-client-actions-container" />
			<div className="crm-entity-widget-client-box-position">
				<span
					style={{
						cursor: 'pointer',
						position: 'absolute',
						right: 0,
					}}
					onClick={props.toggleEdit}
				>
					<FontAwesomeIcon icon={faEdit} />
				</span>
				{props.isEdit ? (
					<div>
						<textarea
							onChange={event => props.change(event.target.value)}
							className="crm-entity-widget-content-input"
							value={props.text === null ? '' : props.text}
						/>
						<div style={{ marginTop: '10px' }}>
							<button onClick={props.save} className="ui-btn ui-btn-xs ui-btn-success">
								Сохранить
							</button>
							<button onClick={props.toggleEdit} className="ui-btn ui-btn-xs ui-btn-link">
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</div>
					</div>
				) : props.text === null ? (
					'информация об ассортименте отсутствует'
				) : (
					props.text
				)}
			</div>
		</div>
	)
}

interface MerchandiseProps {
	id: number
}

interface MerchandiseState {
	item: Item | null
	isEdit: boolean
	isWait: boolean
	fields: MerchandiseItem
}

class Merchandise extends React.Component<MerchandiseProps, MerchandiseState> {
	constructor(props: MerchandiseProps) {
		super(props)
		this.state = {
			fields: {
				brand: '',
				checked: false,
				image: null,
				text: null,
			},
			isEdit: false,
			isWait: false,
			item: null,
		}

		this.setInputFileRef = this.setInputFileRef.bind(this)
	}

	private inputFile: HTMLInputElement

	private setInputFileRef = (element: HTMLInputElement) => {
		this.inputFile = element
	}

	public componentDidMount() {
		Item.load(this.props.id, 'control', 'lists')
			.then(merchandise => {
				const _image = merchandise.getValue('DETAIL_PICTURE')
				// tslint:disable-next-line:strict-type-predicates
				if (_image !== null && _image.length > 0) {
					window.BX24.callMethod(
						'authentica.lists.image.get',
						{ ID: _image },
						(result: BX24.ResultObject) => {
							this.setState({
								fields: this.prepareFields(
									merchandise,
									result.error() ? null : (result.data() as Image)
								),
								isWait: false,
								item: merchandise,
							})
						}
					)
				} else {
					this.setState({
						fields: this.prepareFields(merchandise, null),
						isWait: false,
						item: merchandise,
					})
				}
			})
			.catch(errors => {
				global.console.error('Item.load', errors)
			})
	}

	public render() {
		const { item, isEdit, isWait, fields } = this.state
		return (
			<div className="crm-entity-widget-client-block">
				<div className="crm-entity-widget-client-box">
					<div className="crm-entity-widget-client-box-name-container">
						{item === null || isWait ? (
							<Loading />
						) : (
							<MerchandiseView
								{...fields}
								isEdit={isEdit}
								photo={() => {
									this.inputFile.click()
								}}
								toggle={() => this.toggle()}
								toggleEdit={() => {
									this.setState(update(this.state, { $merge: { isEdit: !this.state.isEdit } }))
								}}
								save={() => this.saveItem()}
								change={value => {
									const field = item.get('DETAIL_TEXT')
									if (field !== undefined) {
										field.setValue([value])
										this.setState(update(this.state, { fields: { $merge: { text: value } } }))
									}
								}}
							/>
						)}
						<input
							style={{ display: 'none' }}
							multiple={false}
							type="file"
							ref={this.setInputFileRef}
							onChange={e => {
								this.addPhoto(e)
							}}
						/>
					</div>
				</div>
			</div>
		)
	}

	/**
	 * Добавляет фотографию
	 * @param event
	 */
	private addPhoto(event: React.ChangeEvent<HTMLInputElement>) {
		const { item } = this.state
		const { files } = event.target
		if (item !== null && files !== null && files.length > 0) {
			this.setState(update(this.state, { $merge: { isWait: true } }), () => {
				const file = files[0]
				global.console.info(`addPhoto`, file)

				const reader = new FileReader()
				reader.onload = () => {
					window.BX24.callMethod(
						'authentica.lists.image.add',
						{
							FIELD: 'DETAIL_PICTURE',
							ID: item.getID(),
							// @ts-ignore
							UPLOAD: `${file.name},${btoa(reader.result)}`,
						},
						(result: BX24.ResultObject) => {
							if (result.error()) {
								global.console.error('add photo error', result.error())
							} else {
								global.console.info('photo saved', result.data())
							}
							this.componentDidMount()
						}
					)
				}
				reader.readAsBinaryString(file)
			})
		}
	}

	/**
	 * Переключает значение поля "Выкладка"
	 */
	private toggle() {
		const { item } = this.state
		if (item !== null) {
			const _place = item.get('PROPERTY_PLACE')
			if (_place !== undefined && _place.DISPLAY_VALUES_FORM !== undefined) {
				const currentValue = +_place.printable()
				const value = Object.keys(_place.DISPLAY_VALUES_FORM).filter(idx => +idx !== currentValue)
				_place.setValue([`${value}`])
				this.saveItem()
			}
		}
	}

	/**
	 * Сохраняет
	 */
	private saveItem() {
		this.setState(update(this.state, { $merge: { isWait: true, isEdit: false } }), () => {
			const { item } = this.state
			if (item !== null) {
				item
					.save()
					.then(() => {
						this.setState(
							update(this.state, {
								$merge: {
									fields: this.prepareFields(item, this.state.fields.image),
									isWait: false,
								},
							})
						)
					})
					.catch(error => {
						global.console.error(`Ошибка сохранения merchandise #${this.props.id}`, error)
						this.componentDidMount()
					})
			}
		})
	}

	private prepareFields(merchandise: Item, _image: Image | null): MerchandiseItem {
		const _brand = merchandise.get('PROPERTY_BRAND')
		const _place = merchandise.get('PROPERTY_PLACE')
		const _text = merchandise.getValue('DETAIL_TEXT')
		return {
			brand:
				_brand !== undefined && _brand.DISPLAY_VALUES_FORM !== undefined
					? _brand.DISPLAY_VALUES_FORM[+_brand.printable()]
					: '-',
			checked: _place !== undefined && _place.DEFAULT_VALUE !== _place.printable(),
			image: _image,
			// tslint:disable-next-line:strict-type-predicates
			text: _text !== null && _text.length === 0 ? null : _text,
		}
	}
}

export default Merchandise
