import * as React from 'react'
import { CardContext } from './types'

interface CardWidgetProps {
	title: string
	children: React.ReactNode
}

export const CardWidget: React.FunctionComponent<CardWidgetProps> = props => (
	<CardContext.Consumer>
		{action => (
			<div className="crm-entity-card-widget">
				<div className="crm-entity-card-widget-title">
					<span className="crm-entity-card-widget-title-text">{props.title}</span>
					{!action.readOnly && (
						<div className="crm-entity-widget-actions-block">
							<span onClick={action.toggle} className="crm-entity-widget-hide-btn">
								{action.isEdit ? 'Отменить' : 'Изменить'}
							</span>
						</div>
					)}
				</div>
				<div className="crm-entity-widget-content">{props.children}</div>
			</div>
		)}
	</CardContext.Consumer>
)
