import * as faEdit from '@fortawesome/fontawesome-free-solid/faEdit'
import * as faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import update from 'immutability-helper'
import * as React from 'react'

export interface StreamSectionProps {
	section: string
	last?: boolean
}

export interface StreamSectionState {
	text: string | undefined
	isAdmin: boolean
	isEdit: boolean
}

class SectionNote extends React.Component<StreamSectionProps, StreamSectionState> {
	constructor(props: StreamSectionProps) {
		super(props)
		this.state = {
			isAdmin: window.BX24.isAdmin(),
			isEdit: false,
			text: window.BX24.appOption.get(this.getOptionName())
		}
		this.toggle = this.toggle.bind(this)
		this.saveText = this.saveText.bind(this)
	}

	public toggle() {
		this.setState(update(this.state, {$merge: {
			isEdit: !this.state.isEdit,
			text: window.BX24.appOption.get(this.getOptionName())
		}}))
	}

	public saveText() {
		window.BX24.appOption.set(
			this.getOptionName(), 
			this.state.text === undefined ? '' : this.state.text,
			() => this.toggle()
		)
	}

	public render() {
		return (
		<div
			className={[
				'crm-entity-stream-section',
				'crm-entity-stream-section-additional',
				// this.props.last !== undefined && this.props.last ? 'crm-entity-stream-section-last' : ''
				].join(' ')
			}
		>
			<div className="crm-entity-widget-content-block-title">
			{this.state.isAdmin && !this.state.isEdit && (
				<span
					style={{
						cursor: 'pointer',
						position: 'absolute',
						right: 0
					}}
					onClick={this.toggle}
				><FontAwesomeIcon icon={faEdit}/>
				</span>
			)}
			{this.state.isEdit ? (
				<div className="crm-entity-card-widget" style={{zIndex: 101}}>
					<div className="crm-entity-widget-content">
						<div className="crm-entity-widget-content-block crm-entity-widget-content-block-field-text">
							<textarea 
								onChange={(event) => {
									this.setState(update(this.state, {$merge: {text: event.target.value}}))
								}}
								className="crm-entity-widget-content-input"
								value={this.state.text === undefined ? '' : this.state.text}
							/>
							<div style={{marginTop: '10px'}}>
								<button 
									onClick={this.saveText}
									className="ui-btn ui-btn-xs ui-btn-success"
								>Сохранить
								</button>
								<button
									onClick={this.toggle}
									className="ui-btn ui-btn-xs ui-btn-link"
								><FontAwesomeIcon icon={faTimes}/>
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div>
					{this.state.text !== undefined && (
						this.state.text.split('\n').map((s, idx) => (<span key={idx}>{s}<br/></span>))
					)}
				</div>
			)}
			</div>
		</div>
		)
	}

	private getOptionName() {
		return `SectionNote_${this.props.section}`
	}
}

export default SectionNote