import * as moment from 'moment'
import 'moment/locale/ru'
import * as React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// @ts-ignore
import Select from 'react-select'
import { FieldType, Value, RENDER_TYPE } from './fieldtype'
import * as FieldRender from './render/index'
import { CardContext } from './types'

moment.locale('ru')

interface FieldViewWrapProps {
    type: string
    children: React.ReactNode
}

const FieldViewWrap: React.FunctionComponent<FieldViewWrapProps> = ({ children, type }) => (
    <div className="crm-entity-widget-content-block-inner">
        <span className={`fields ${type} field-wrap`}>
            <span className={`fields ${type} field-item`}>{children}</span>
        </span>
    </div>
)

export interface SelectValue {
    label: string
    value: string
}

export interface FieldProps {
    field?: FieldType
    printable?: string
    readonly?: boolean
    defaultValues?: SelectValue[]
    filter?: (id: string) => boolean
}

interface FieldEditProps extends FieldProps {
    onChange?(field: string, value: string, oldValue: string): void
}

const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm'

const FieldEdit: React.FunctionComponent<FieldEditProps> = ({
    field,
    defaultValues,
    onChange,
    filter,
}) => {
    if (field === undefined) {
        return null
    }

    const setValue = (value: string[] | Value) => {
        const oldValue = field.toString()
        field.setValue(value)
        if (onChange !== undefined) {
            onChange(field.getCode(), field.toString(), oldValue)
        }
    }

    switch (field.getRenderType()) {
        case RENDER_TYPE.EMPLOYEE:
            return <FieldRender.User field={field} isEdit={true} onChange={onChange} />

        case RENDER_TYPE.CRM:
            return (
                <FieldRender.Crm
                    defaultValues={defaultValues}
                    field={field}
                    isEdit={true}
                    onChange={onChange}
                />
            )

        case RENDER_TYPE.LIST_ENUM:
        case RENDER_TYPE.IBLOCK_ELEMENETS:
            const { DISPLAY_VALUES_FORM } = field
            return DISPLAY_VALUES_FORM === undefined ? null : (
                <div className="crm-entity-widget-content-block-inner">
                    <Select
                        className="react-select"
                        value={field.getValue().map(v => ({
                            label: DISPLAY_VALUES_FORM[v],
                            value: v,
                        }))}
                        isMulti={field.isMultiple() ? true : undefined}
                        isDisabled={false}
                        isClearable={false}
                        isSearchable={true}
                        placeholder="Выберите..."
                        options={Object.keys(DISPLAY_VALUES_FORM)
                            .filter(filter === undefined ? id => true : filter)
                            .map(id => ({
                                label: DISPLAY_VALUES_FORM[id],
                                value: `${id}`,
                            }))}
                        onChange={(newValue: SelectValue | SelectValue[]) => {
                            const value = Array.isArray(newValue) ? newValue : [newValue]
                            setValue(value.map(v => v.value))
                        }}
                    />
                </div>
            )

        case RENDER_TYPE.DATETIME:
            return /(ipad|iphone|android|mobile|touch)/i.test(navigator.userAgent) ? (
                <div className="crm-entity-widget-content-block-inner">
                    {field.getValues().map(v => (
                        <div key={v.id}>
                            <input
                                type="datetime-local"
                                className="crm-entity-widget-content-input-datetime"
                                value={
                                    // tslint:disable-next-line:strict-type-predicates
                                    v.value !== undefined && v.value !== null && v.value.length > 0
                                        ? moment(v.value, DATETIME_FORMAT).format(
                                              'YYYY-MM-DDTHH:mm'
                                          )
                                        : undefined
                                }
                                onChange={event => {
                                    setValue({
                                        id: v.id,
                                        value: moment(
                                            event.target.value,
                                            'YYYY-MM-DDTHH:mm'
                                        ).format(DATETIME_FORMAT),
                                    })
                                }}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="crm-entity-widget-content-block-inner">
                    {field.getValues().map(v => (
                        <DatePicker
                            key={v.id}
                            showTimeSelect={true}
                            className="crm-entity-widget-content-input"
                            timeFormat="HH:mm"
                            dateFormat="DD.MM.YYYY HH:mm"
                            timeIntervals={30}
                            locale="ru-ru"
                            selected={
                                // tslint:disable-next-line:strict-type-predicates
                                v.value !== undefined && v.value !== null && v.value.length > 0
                                    ? moment(v.value, DATETIME_FORMAT)
                                    : undefined
                            }
                            onChange={(datetime: moment.Moment) => {
                                const newValue = datetime.format(DATETIME_FORMAT)
                                setValue({
                                    id: v.id,
                                    value: newValue,
                                })
                            }}
                        />
                    ))}
                </div>
            )

        default:
            return (
                <div className="crm-entity-widget-content-block-inner">
                    {field.getValues().map(v => (
                        <input
                            key={v.id}
                            className="crm-entity-widget-content-input"
                            type="text"
                            value={field.getValue()}
                            onChange={event => {
                                setValue({
                                    id: v.id,
                                    value: event.target.value,
                                })
                            }}
                        />
                    ))}
                </div>
            )
    }
}

const FieldView: React.FunctionComponent<FieldProps> = ({ field, printable }) => {
    if (printable !== undefined) {
        return <span>{printable}</span>
    }

    if (field === undefined) {
        return null
    }

    const type = field.getRenderType()
    switch (type) {
        case RENDER_TYPE.EMPLOYEE:
            return <FieldRender.User field={field} isEdit={false} />

        case RENDER_TYPE.CRM:
            return <FieldRender.Crm field={field} isEdit={false} />

        case RENDER_TYPE.LIST_ENUM:
        case RENDER_TYPE.IBLOCK_ELEMENETS:
            return field.isMultiple() ? (
                <FieldViewWrap type={type}>
                    <ul>
                        {field.getValues().map(v => (
                            <li key={v.value}>
                                {field.DISPLAY_VALUES_FORM === undefined
                                    ? `#${v.value}`
                                    : field.DISPLAY_VALUES_FORM[v.value]}
                            </li>
                        ))}
                    </ul>
                </FieldViewWrap>
            ) : (
                <FieldViewWrap type={type}>
                    {field
                        .getValues()
                        .map(v =>
                            field.DISPLAY_VALUES_FORM === undefined
                                ? `#${v.value}`
                                : field.DISPLAY_VALUES_FORM[v.value]
                        )
                        .join(' | ')}
                </FieldViewWrap>
            )

        case RENDER_TYPE.DATETIME:
            return (
                <FieldViewWrap type={type}>
                    {printable === undefined
                        ? moment(field.getValue(), DATETIME_FORMAT).format('DD.MM.YYYY HH:mm')
                        : printable}
                </FieldViewWrap>
            )

        default:
            return <FieldViewWrap type={type}>{field.toString()}</FieldViewWrap>
    }
}

export const Field: React.FunctionComponent<FieldProps> = props => {
    const { field } = props
    if (field === undefined) {
        return null
    }

    return (
        <CardContext.Consumer>
            {action => {
                const invalid =
                    action.isEdit && action.isValidate && field.isRequired() && field.isEmpty()
                const classes = [
                    'crm-entity-widget-content-block',
                    'crm-entity-widget-content-block-field-text',
                ]
                if (invalid) {
                    classes.push('crm-entity-widget-block-error')
                }
                return (
                    (action.isEdit ||
                        !action.isHideEmpty ||
                        (action.isHideEmpty && !field.isEmpty())) && (
                        <div className={classes.join(' ')}>
                            {field.NAME !== undefined && (
                                <div className="crm-entity-widget-content-block-title">
                                    <span className="crm-entity-widget-content-block-title-text">
                                        {field.NAME}{' '}
                                    </span>
                                    {action.isEdit && field.isRequired() && (
                                        <span style={{ color: 'red' }}>*</span>
                                    )}
                                </div>
                            )}
                            {action.isEdit && (props.readonly === undefined || !props.readonly) ? (
                                <FieldEdit {...props} onChange={action.onChange} />
                            ) : (
                                <FieldView {...props} />
                            )}
                            {invalid && (
                                <div className="crm-entity-widget-content-error-text">
                                    Пожалуйста, введите значение.
                                </div>
                            )}
                        </div>
                    )
                )
            }}
        </CardContext.Consumer>
    )
}
