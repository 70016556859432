import * as faArrowCircleLeft from '@fortawesome/fontawesome-free-solid/faArrowCircleLeft'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import update from 'immutability-helper'
import * as React from 'react'
import { Header } from './markup'
import { CardAction, CardContext } from './types'

interface CardState {
    isEdit: boolean
    isValidate: boolean
    isSaving: boolean
    error: null | string
}

interface CardProps {
    title: string | React.ReactNode | null
    isDefaultEdit?: boolean
    isHideEmpty?: boolean
    readOnly?: boolean
    back?(): void
    onChange?(field: string, value: string, oldValue: string): void
    onSave?(): Promise<number>
    onCancel?(): void
}

class Card extends React.Component<CardProps, CardState> {
    public state = {
        error: null,
        isEdit: this.props.isDefaultEdit === undefined ? false : this.props.isDefaultEdit,
        isValidate: false,
        isSaving: false,
    }

    public toggle() {
        if (this.state.isEdit) {
            this.setState(
                update(this.state, {
                    $merge: {
                        error: null,
                        isEdit: false,
                        isValidate: false,
                    },
                }),
                () => {
                    if (this.props.onCancel !== undefined) {
                        this.props.onCancel()
                    }
                }
            )
        } else {
            this.setState(update(this.state, { $merge: { isEdit: true } }))
        }
    }

    public render() {
        return (
            <CardContext.Provider value={this.initContext()}>
                {this.props.title === null ? (
                    <br />
                ) : (
                    <Header>
                        {this.props.back !== undefined ? (
                            <div>
                                <span className="back-button" onClick={this.props.back}>
                                    <FontAwesomeIcon icon={faArrowCircleLeft} />
                                </span>
                                <span>{this.props.title}</span>
                            </div>
                        ) : (
                            this.props.title
                        )}
                    </Header>
                )}
                <div
                    style={
                        this.props.isDefaultEdit
                            ? {
                                  position: 'relative',
                                  zIndex: 200,
                              }
                            : undefined
                    }
                    className="crm-entity-section crm-entity-section-info"
                >
                    <div className="crm-entity-card-container">{this.props.children}</div>
                </div>
                {this.state.isEdit && (
                    <div className="crm-entity-wrap crm-section-control-active">
                        <div className="crm-entity-section crm-entity-section-control">
                            <button
                                onClick={() => {
                                    this.setState(
                                        update(this.state, {
                                            $merge: {
                                                isSaving: true,
                                            },
                                        }),
                                        () => {
                                            if (this.props.onSave !== undefined) {
                                                this.props
                                                    .onSave()
                                                    .then(id => {
                                                        this.setState(
                                                            update(this.state, {
                                                                $merge: {
                                                                    error: null,
                                                                    isEdit: false,
                                                                    isValidate: false,
                                                                    isSaving: false,
                                                                },
                                                            })
                                                        )
                                                    })
                                                    .catch((saveError: string) => {
                                                        this.setState(
                                                            // @ts-ignore
                                                            update(this.state, {
                                                                $merge: {
                                                                    error: saveError,
                                                                    isValidate: true,
                                                                    isSaving: false,
                                                                },
                                                            })
                                                        )
                                                    })
                                            }
                                        }
                                    )
                                }}
                                disabled={this.state.isSaving ? true : undefined}
                                className="ui-btn ui-btn-success"
                            >
                                Сохранить
                            </button>
                            <a
                                onClick={() => {
                                    this.toggle()
                                }}
                                className="ui-btn ui-btn-link"
                            >
                                Отменить
                            </a>
                            {// tslint:disable-next-line:strict-type-predicates
                            this.state.error !== null && (
                                <div className="crm-entity-section-control-error-block">
                                    {this.state.error}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {this.props.isDefaultEdit && <div className="crm-entity-overlay" />}
            </CardContext.Provider>
        )
    }

    private initContext(): CardAction {
        return {
            isEdit: this.state.isEdit,
            isHideEmpty: this.props.isHideEmpty === undefined ? true : this.props.isHideEmpty,
            isValidate: this.state.isValidate,
            onChange: this.props.onChange,
            readOnly: this.props.readOnly === undefined ? false : this.props.readOnly,
            toggle: () => {
                this.toggle()
            },
        }
    }
}

export { Card }
