import * as moment from 'moment'
import * as React from 'react'
// @ts-ignore
import renderHTML from 'react-render-html'

export const Header: React.FunctionComponent<{}> = ({ children }) => (
	<div className="crm-iframe-header">
		<div className="pagetitle-wrap">
			<div className="pagetitle-inner-container">
				<div className="pagetitle">
					<span className="pagetitle-item">{children}</span>
				</div>
			</div>
		</div>
	</div>
)

export const CardContainer: React.FunctionComponent<{}> = ({ children }) => (
	<div className="crm-entity-card-container-content">
		<form>{children}</form>
	</div>
)

export const StreamContainer: React.FunctionComponent<{}> = ({ children }) => (
	<div className="crm-entity-stream-container">
		<div className="crm-entity-stream-container-content">
			<div className="crm-entity-stream-container-list">{children}</div>
		</div>
	</div>
)

export interface StreamSectionNewActionProps {
	active?: boolean
	onClick(): void
}

export const StreamSectionNewAction: React.FunctionComponent<
	StreamSectionNewActionProps
> = props => (
	<div
		className={`crm-entity-stream-section-new-action${
			props.active !== undefined && props.active
				? ' crm-entity-stream-section-new-action-active'
				: ''
		}`}
		onClick={props.onClick}
	>
		{props.children}
	</div>
)

export interface StreamSectionNewProps {
	header: React.ReactNode[]
}

export const StreamSectionNew: React.FunctionComponent<StreamSectionNewProps> = props => (
	<div className="crm-entity-stream-section crm-entity-stream-section-new">
		<div className="crm-entity-stream-section-icon crm-entity-stream-section-icon-new" />
		<div className="crm-entity-stream-section-content crm-entity-stream-section-content-new">
			<div className="crm-entity-stream-section-content-new-header">{props.header}</div>
			{props.children}
		</div>
	</div>
)

export interface StreamSectionProps {
	title: string
	color: string
	last?: boolean
}

export const StreamSection: React.FunctionComponent<StreamSectionProps> = props => (
	<div>
		<div className="crm-entity-stream-section crm-entity-stream-section-planned-label">
			<div className="crm-entity-stream-section-content">
				<div className="crm-entity-stream-planned-label" style={{ backgroundColor: props.color }}>
					{props.title}
				</div>
			</div>
		</div>
		{props.children}
	</div>
)

export interface StreamSectionEventProps {
	title: string
	header: string
	icon: string
	isClosed: boolean
	color: string
	date: moment.Moment
	onClick?(): void
}

export const StreamSectionEvent: React.FunctionComponent<StreamSectionEventProps> = props => (
	<div
		className={[
			'crm-entity-stream-section',
			props.isClosed ? '' : 'crm-entity-stream-section-planned',
		].join(' ')}
	>
		<div
			className={[
				'crm-entity-stream-section-icon',
				`crm-entity-stream-section-icon-${props.icon}`,
				props.isClosed ? '' : 'crm-entity-stream-section-counter',
			].join(' ')}
			style={{ backgroundColor: props.color }}
		/>
		<div className="crm-entity-stream-section-content">
			<div className="crm-entity-stream-content-event">
				<div className="crm-entity-stream-content-header">
					{props.onClick === undefined ? (
						<span className="crm-entity-stream-content-event-title">{props.header}</span>
					) : (
						<a href="#" onClick={props.onClick} className="crm-entity-stream-content-event-title">
							{props.header}
						</a>
					)}
					<span className="crm-entity-stream-content-event-time">{props.date.fromNow()}</span>
				</div>
				<div className="crm-entity-stream-content-detail">
					{props.onClick === undefined ? (
						renderHTML(props.title)
					) : (
						<a href="#" onClick={props.onClick}>
							{props.title}
						</a>
					)}
				</div>
			</div>
		</div>
	</div>
)
