import * as faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import update from 'immutability-helper'
import * as React from 'react'
// @ts-ignore
import RichEditor from 'rich-editor'
import { Button } from '../entity'

import 'rich-editor/dist/rich-editor.css'
import './form-comment.css'

export interface TimelineCategory {
    key: string
    title: string
    color: string
    uf: string[]
}

export interface CommentFormProps {
    visit?: number
    user?: BX24.UserProfile
    categories: TimelineCategory[]
    isPlane: boolean
    cancel(): void
    apply(): void
}

interface CommentFormState {
    category: TimelineCategory | null
    text: string
    text2: string
    text3: string
    text4: string
    textOnly: boolean
}

export class CommentForm extends React.Component<CommentFormProps, CommentFormState> {
    constructor(props: CommentFormProps) {
        super(props)
        this.state = {
            category: null,
            text: '',
            text2: '',
            text3: '',
            text4: '',
            textOnly: false,
        }
        this.richEditorRef = React.createRef()
        this.richEditorRef2 = React.createRef()
        this.richEditorRef3 = React.createRef()
        this.richEditorRef4 = React.createRef()
    }

    private richEditorRef: React.RefObject<RichEditor>
    private richEditorRef2: React.RefObject<RichEditor>
    private richEditorRef3: React.RefObject<RichEditor>
    private richEditorRef4: React.RefObject<RichEditor>

    public addOneComment(
        title: string,
        text: string,
        richEditorRef: React.RefObject<RichEditor>,
        sort: number
    ) {
        if (this.state.category === null) {
            return
        }

        if (!this.state.textOnly && richEditorRef.current === null) {
            return
        }

        const name =
            this.props.user === undefined
                ? `#${this.props.visit}`
                : `${this.props.user.NAME} ${this.props.user.LAST_NAME}`

        const outText = this.state.textOnly ? text : richEditorRef.current.getHTML()

        if (outText.length > 0) {
            window.BX24.callMethod(
                'entity.item.add',
                {
                    CODE: this.state.category.key,
                    ENTITY: 'visit_comments',
                    NAME: name,
                    SORT: `${sort}`,
                    PREVIEW_TEXT: `<b>${title}</b>:<br/>${outText}`,
                    XML_ID: `${this.props.visit}`,
                },
                (result: BX24.ResultObject) => {
                    this.props.apply()
                    window.BX24.callMethod(
                        'authentica.visit.comment.update',
                        {
                            visitId: `${this.props.visit}`,
                        },
                        // tslint:disable-next-line: no-empty
                        () => {}
                    )
                }
            )
        }
    }

    public addComment() {
        if (this.state.category === null) {
            return
        }

        const { text, text2, text3, text4 } = this.state

        if (this.props.isPlane) {
            this.addOneComment('Цель', text, this.richEditorRef, 500)
            this.addOneComment('Что делаю', text2, this.richEditorRef2, 501)
            this.addOneComment('План результат', text3, this.richEditorRef3, 502)
        } else {
            this.addOneComment('Итог результат', text4, this.richEditorRef4, 503)
        }
    }

    public render() {
        const { category, text, text2, text3, text4, textOnly } = this.state
        return (
            <div className="crm-entity-stream-content-new-detail focus">
                <div
                    className="crm-entity-stream-section-comment-editor"
                    style={{ display: 'none' }}
                >
                    <div className="crm-entity-stream-section-content-new-header" />
                </div>
                {category !== null && (
                    <div className="crm-entity-stream-section-comment-editor">
                        {!this.props.isPlane && (
                            <div>
                                <div
                                    className="crm-entity-card-widget-title"
                                    style={{ borderBottom: 'none' }}
                                >
                                    <span className="crm-entity-card-widget-title-text">
                                        Итог результат:
                                    </span>
                                </div>
                                {textOnly ? (
                                    <textarea
                                        onChange={event =>
                                            this.setState(
                                                update(this.state, {
                                                    $merge: { text4: event.target.value },
                                                })
                                            )
                                        }
                                        value={text4}
                                    />
                                ) : (
                                    <RichEditor ref={this.richEditorRef4} />
                                )}
                            </div>
                        )}
                        {this.props.isPlane && (
                            <div>
                                <div
                                    className="crm-entity-card-widget-title"
                                    style={{ borderBottom: 'none' }}
                                >
                                    <span className="crm-entity-card-widget-title-text">Цель:</span>
                                </div>
                                {textOnly ? (
                                    <textarea
                                        onChange={event =>
                                            this.setState(
                                                update(this.state, {
                                                    $merge: { text: event.target.value },
                                                })
                                            )
                                        }
                                        value={text}
                                    />
                                ) : (
                                    <RichEditor ref={this.richEditorRef} />
                                )}
                            </div>
                        )}
                        {this.props.isPlane && (
                            <div>
                                <div
                                    className="crm-entity-card-widget-title"
                                    style={{ borderBottom: 'none' }}
                                >
                                    <span className="crm-entity-card-widget-title-text">
                                        Что делаю:
                                    </span>
                                </div>
                                {textOnly ? (
                                    <textarea
                                        onChange={event =>
                                            this.setState(
                                                update(this.state, {
                                                    $merge: { text2: event.target.value },
                                                })
                                            )
                                        }
                                        value={text2}
                                    />
                                ) : (
                                    <RichEditor ref={this.richEditorRef2} />
                                )}
                            </div>
                        )}
                        {this.props.isPlane && (
                            <div>
                                <div
                                    className="crm-entity-card-widget-title"
                                    style={{ borderBottom: 'none' }}
                                >
                                    <span className="crm-entity-card-widget-title-text">
                                        План результат:
                                    </span>
                                </div>
                                {textOnly ? (
                                    <textarea
                                        onChange={event =>
                                            this.setState(
                                                update(this.state, {
                                                    $merge: { text3: event.target.value },
                                                })
                                            )
                                        }
                                        value={text3}
                                    />
                                ) : (
                                    <RichEditor ref={this.richEditorRef3} />
                                )}
                            </div>
                        )}
                    </div>
                )}
                {category === null ? (
                    <div className="crm-entity-stream-content-new-comment-btn-container">
                        {this.props.categories.map(item => (
                            <button
                                onClick={() =>
                                    this.setState(
                                        update(this.state, { $merge: { category: item } })
                                    )
                                }
                                className="ui-btn ui-btn-xs"
                                key={item.key}
                                style={{ backgroundColor: item.color, marginRight: '10px' }}
                            >
                                {item.title}
                            </button>
                        ))}
                        <span
                            onClick={this.props.cancel}
                            style={{ float: 'right' }}
                            className="ui-btn ui-btn-xs ui-btn-link"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </div>
                ) : (
                    <div className="crm-entity-stream-content-new-comment-btn-container">
                        <Button
                            onClick={() => this.addComment()}
                            style={{ backgroundColor: category.color }}
                            className="ui-btn ui-btn-xs"
                        >
                            Отправить в "{category.title}"
                        </Button>
                        <span
                            onClick={() =>
                                this.setState(update(this.state, { $merge: { category: null } }))
                            }
                            className="ui-btn ui-btn-xs ui-btn-link"
                        >
                            Отмена
                        </span>
                    </div>
                )}
            </div>
        )
    }
}
