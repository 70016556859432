import update from 'immutability-helper'
import * as React from 'react'
import { FieldType } from './../fieldtype'
import Loading from './../loading'

interface UserProps {
    field: FieldType
    isEdit: boolean
    onChange?(field: string, value: string, oldValue: string): void
}

interface UserItem {
    ID: string
    NAME: string
    LAST_NAME: string
    EMAIL: string
    SECOND_NAME: string
    PERSONAL_PHOTO: string
    WORK_POSITION: string
}

interface UserState {
    users: UserItem[]
    isReady: boolean
}

export class User extends React.Component<UserProps, UserState> {
    public static getDerivedStateFromProps(
        nextProps: UserProps,
        prevState?: UserState
    ): UserState | null {
        if (prevState !== undefined) {
            const prevIDs = prevState.users
                .map(user => user.ID)
                .sort((a, b) => a.localeCompare(b))
                .join(',')
            const nextIDs = nextProps.field
                .getValue()
                .sort((a, b) => a.localeCompare(b))
                .join(',')
            if (prevIDs === nextIDs) {
                return null
            }
        }
        return {
            isReady: false,
            users: [],
        }
    }

    constructor(props: UserProps) {
        super(props)
        this.state = User.getDerivedStateFromProps(props) as UserState
        this.selectUser = this.selectUser.bind(this)
    }

    public componentDidUpdate() {
        if (!this.state.isReady) {
            this.componentDidMount()
        }
    }

    public componentDidMount() {
        const batch: object = {}
        this.props.field
            .getValue()
            .filter(u => u.length > 0)
            .forEach(userID => {
                batch[userID] = [`user.get`, { ID: userID }]
            })
        if (Object.keys(batch).length > 0) {
            const newUsers: UserItem[] = []
            window.BX24.callBatch(batch, (batchResult: object) => {
                Object.keys(batch).forEach(id => {
                    const result: BX24.Result = batchResult[id]
                    if (result.error()) {
                        global.console.error(result.error())
                        return
                    }
                    result.data().forEach((user: UserItem) => newUsers.push(user))
                })
                this.setState(
                    update(this.state, {
                        $merge: {
                            isReady: true,
                            users: newUsers,
                        },
                    }),
                    () => {
                        // global.console.info('User::componentDidMount', this.state)
                    }
                )
            })
        } else {
            this.setState(update(this.state, { $merge: { isReady: true } }))
        }
    }

    public setUsers(values: string[]) {
        const { field } = this.props
        const oldValue = field.toString()
        field.setValue(values)
        if (this.props.onChange !== undefined) {
            this.props.onChange(field.getCode(), field.toString(), oldValue)
        }
    }

    public selectUser() {
        const { field } = this.props
        if (field.isMultiple()) {
            window.BX24.selectUsers((users: BX24.SelectUserResult[]) => {
                const values = field.getValue()
                users.forEach(user => {
                    if (values.indexOf(user.id) < 0) {
                        values.push(user.id)
                    }
                })
                this.setUsers(values)
            })
        } else {
            window.BX24.selectUser((user: BX24.SelectUserResult) => {
                this.setUsers([user.id])
            })
        }
    }

    public render() {
        if (!this.state.isReady) {
            return <Loading />
        }
        return this.props.isEdit ? (
            <div className="crm-entity-widget-content-block-text">
                <div className="crm-entity-widget-content-block-inner">
                    <div className="main-ui-control-entity main-ui-control userfieldemployee-control">
                        {this.state.users.map(user => (
                            <div
                                key={user.ID}
                                onClick={() => {
                                    const { field } = this.props
                                    this.setUsers(
                                        field.getValue().filter(userID => userID !== user.ID)
                                    )
                                }}
                                className="main-ui-square"
                            >
                                <div className="main-ui-square-item">
                                    {user.NAME} {user.LAST_NAME}
                                </div>
                                <div className="main-ui-item-icon main-ui-square-delete" />
                            </div>
                        ))}
                        <a className="feed-add-destination-link" onClick={this.selectUser}>
                            Выбрать
                        </a>
                    </div>
                </div>
            </div>
        ) : (
            <div className="crm-entity-widget-content-block-text">
                {this.state.users.map(user => (
                    <div className="crm-entity-widget-content-block-inner" key={user.ID}>
                        <span className="fields employee field-wrap">
                            <span className="fields employee field-item">
                                <a
                                    className="uf-employee-wrap"
                                    style={{ width: '100%' }}
                                    href={`https://${window.BX24.getDomain()}/company/personal/user/${
                                        user.ID
                                    }/`}
                                    target="_blank"
                                >
                                    <span
                                        className="uf-employee-image"
                                        style={
                                            // tslint:disable-next-line:strict-type-predicates
                                            user.PERSONAL_PHOTO === null
                                                ? undefined
                                                : {
                                                      backgroundImage: `url(\"${user.PERSONAL_PHOTO}\")`,
                                                      backgroundSize: '30px',
                                                  }
                                        }
                                    />
                                    <span className="uf-employee-data">
                                        <span className="uf-employee-name">
                                            {user.NAME} {user.LAST_NAME}
                                        </span>
                                        <span className="uf-employee-position">
                                            {user.WORK_POSITION}
                                        </span>
                                    </span>
                                </a>
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        )
    }
}
