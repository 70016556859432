import * as faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Item } from '../entity/'

export interface OrderFormProps {
	visit?: number
	salon?: string
	cancel(): void
}

const VISIT_ORDER = 'VISIT_ORDER'

export const OrderForm: React.FunctionComponent<OrderFormProps> = props => (
	<div className="crm-entity-stream-content-new-detail focus">
		<div className="crm-entity-stream-section-comment-editor" style={{ display: 'none' }}>
			<div className="crm-entity-stream-section-content-new-header" />
		</div>
		<div className="crm-entity-stream-content-new-comment-btn-container">
			<button
				className="ui-btn ui-btn-xs ui-btn-success"
				onClick={() =>
					Item.loadEx('ELEMENT_CODE', VISIT_ORDER, 'ref_tasks', 'lists').then(task => {
						if (task.getValue('PROPERTY_RESPONSIBLE').length === 0) {
							alert(
								'Ошибка!\n\n' +
									'Для задачи "Создать заказ для ЦП" в списке "Задачи: дополнительные для визиты"' +
									' не определен ответственный.'
							)
						} else {
							const params = {
								responsible: task.getValue('PROPERTY_RESPONSIBLE'),
								salonID: props.salon,
								title: 'Заказ для ЦП',
								visitID: props.visit,
							}

							window.top.postMessage(
								`CustomVisitHelper:openNewTask:${JSON.stringify(params)}`,
								`https://${window.BX24.getDomain()}`
							)
						}
					})
				}
			>
				Создать заказ для ЦП
			</button>
			<span
				onClick={props.cancel}
				className="ui-btn ui-btn-xs ui-btn-link"
				style={{ float: 'right' }}
			>
				<FontAwesomeIcon icon={faTimes} />
			</span>
		</div>
	</div>
)
