import * as faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as React from 'react'
import * as Entity from '../entity'

/**
 * XML_ID для Enum полей
 */
export enum ACTION_TARGET {
	ALL = 'ALL',
	SALON_ALL = 'SALON_ALL',
	SALON_ISHOP = 'SALON_ISHOP',
	SALON_RETAIL = 'SALON_RETAIL',
	FAVORITE = 'FAVORITE',
}

export interface ActionItem {
	iblockCode: string
	title: string
	uf: string
}

export interface RefItem {
	ID: string
	NAME: string
	DESCRIPTION: string
	PREVIEW_TEXT: string
	DETAIL_TEXT: string
	PROPERTY_RESPONSIBLE: string[]
	IBLOCK_SECTION_ID: string | null
	IBLOCK_SECTION_NAME?: string
}

export interface ActionFormProps {
	entity: Entity.Item
	cancel(): void
	apply(): void
}

export interface TaskItem {
	ACCOMPLICES?: string[]
	DEADLINE?: string
	DESCRIPTION?: string
	RESPONSIBLE_ID?: string
	TITLE?: string
	UF_CRM_TASK?: string[]
	UF_TASK_VISIT?: number
	TAGS: string[]
}

interface ActionFormState {
	items: ActionItem[]
}

interface FieldType {
	PROPERTY_TYPE: boolean | string
	FIELD_ID: string
	CODE: string
}

export abstract class ActionHelper extends React.Component<ActionFormProps, ActionFormState> {
	constructor(props: ActionFormProps) {
		super(props)
		this.state = {
			items: this.getItems(),
		}
		this.handleMessage = this.handleMessage.bind(this)
	}

	public abstract getItems(): ActionItem[]

	public abstract getFilter(iblockCode: string): Promise<object>

	public abstract handleSelectedItems(iblockCode: string, items: RefItem[]): void

	public componentDidMount() {
		window.addEventListener('message', this.handleMessage)
	}

	public componentWillUnmount() {
		window.removeEventListener('message', this.handleMessage)
	}

	public openRef(iblockCode: string) {
		const action = this.state.items.find(a => a.iblockCode === iblockCode)
		if (action !== undefined) {
			this.getFilter(iblockCode)
				.then(filterData => {
					window.top.postMessage(
						`CustomVisitHelper:openRef:${JSON.stringify({
							filter: filterData,
							mode: 'selector',
							ref: iblockCode,
						})}`,
						`https://${window.BX24.getDomain()}`
					)
				})
				.catch(e => {
					global.console.error(`OpenRer[${iblockCode}]`, e)
				})
		}
	}

	public render() {
		const { items } = this.state
		return (
			<div className="crm-entity-stream-content-new-detail focus">
				<div className="crm-entity-stream-section-comment-editor" style={{ display: 'none' }}>
					<div className="crm-entity-stream-section-content-new-header" />
				</div>
				<div className="crm-entity-stream-content-new-comment-btn-container">
					{this.props.children}
					{items.map(a => (
						<button
							key={a.iblockCode}
							className="ui-btn ui-btn-xs ui-btn-primary"
							onClick={() => this.openRef(a.iblockCode)}
						>
							{a.title}
						</button>
					))}
					<span
						onClick={this.props.cancel}
						className="ui-btn ui-btn-xs ui-btn-link"
						style={{ float: 'right' }}
					>
						<FontAwesomeIcon icon={faTimes} />
					</span>
				</div>
			</div>
		)
	}

	private handleMessage(e: MessageEvent) {
		e = e || window.event

		if (e.data === undefined && e.data === null && e.data.length === 0) {
			return
		}

		const cmd = e.data.split(':')
		if (cmd.shift() !== 'CustomVisitHelper' || cmd.length < 3) {
			return
		}

		const command = cmd.shift()
		const params = JSON.parse(cmd.join(':'))

		switch (command) {
			case 'sendRefItems':
				e.stopPropagation()
				const listParams = {
					FILTER: { ID: params.items },
					IBLOCK_CODE: params.iblockCode,
					IBLOCK_TYPE_ID: 'lists',
				}
				window.BX24.callBatch(
					[
						['lists.field.get', listParams],
						['lists.element.get', listParams],
						['authentica.lists.section.get', { IBLOCK_CODE: params.iblockCode }],
					],
					(result: BX24.BatchResult) => {
						if (result[0].error()) {
							global.console.error(result[0].error())
							return
						}

						/**
						 * Конвертируем FIELD_ID в CODE для PROPERTY_
						 * Конвертируем object значений в массив
						 */

						const sections = result[2].data()
						const items = result[1].data() as RefItem[]
						const fields = result[0].data() as FieldType[]

						Object.keys(fields).forEach(idx => {
							const field = fields[idx]
							if (field.PROPERTY_TYPE !== false) {
								items.forEach((item, i) => {
									const code = `PROPERTY_${field.CODE}`
									items[i][code] = items[i][field.FIELD_ID]
									delete items[i][field.FIELD_ID]

									if (items[i].IBLOCK_SECTION_ID !== null) {
										sections.forEach((s: { ID: string; NAME: string }) => {
											if (s.ID === items[i].IBLOCK_SECTION_ID) {
												items[i].IBLOCK_SECTION_NAME = s.NAME
											}
										})
									}

									items[i][code] =
										items[i][code] === undefined || items[i][code] === null
											? []
											: Object.keys(items[i][code]).map(key => items[i][code][key])
								})
							}
						})

						this.handleSelectedItems(params.iblockCode, items)
					}
				)
				break

			default:
				global.console.info(`CustomVisitHelper[${command}]`, params)
		}
	}
}
