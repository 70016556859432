import * as faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Item } from '../entity/'

export interface ControlFormProps {
	visit?: number
	salon?: string
	cancel(): void
	apply(): void
}

const UF_BRANDS = 'UF_CRM_1510169239'

export const ControlForm: React.FunctionComponent<ControlFormProps> = props => (
	<div className="crm-entity-stream-content-new-detail focus">
		<div className="crm-entity-stream-section-comment-editor" style={{ display: 'none' }}>
			<div className="crm-entity-stream-section-content-new-header" />
		</div>
		<div className="crm-entity-stream-content-new-comment-btn-container">
			<Button
				className="ui-btn ui-btn-xs ui-btn-success"
				onClick={callback =>
					window.BX24.callMethod(
						'crm.company.get',
						{ id: props.salon },
						(result: BX24.ResultObject) => {
							if (result.error()) {
								global.console.error('Ошибка загрузки салона', result.error())
							} else {
								const company = result.data()
								if (!Array.isArray(company[UF_BRANDS])) {
									alert('Ошибка! Для салона не выбраны бренды')
									callback()
									return
								}

								const items: Array<Promise<Item>> = company[UF_BRANDS].map((brandID: string) =>
									Item.init('control', 'lists', {
										NAME: [`${props.visit}/${props.salon}/${brandID}`],
										PROPERTY_BRAND: brandID,
										PROPERTY_SALON: props.salon,
										PROPERTY_VISIT: props.visit,
									})
								)

								let count = 0
								const checkDone = () => {
									count++
									if (count >= items.length) {
										global.console.info('batchResult', items)
										callback()
										props.apply()
									}
								}

								items.forEach(p => {
									p.then(item => {
										item
											.save()
											.then(checkDone)
											.catch(error => {
												global.console.error(`Ошибка сохранения ${item.getValue('NAME')}`, error)
												checkDone()
											})
									}).catch(e => {
										global.console.error('ControlForm', e)
									})
								})
							}
						}
					)
				}
			>
				Выполнить контроль мерчендайзинга и ассортимента
			</Button>
			<span
				onClick={props.cancel}
				className="ui-btn ui-btn-xs ui-btn-link"
				style={{ float: 'right' }}
			>
				<FontAwesomeIcon icon={faTimes} />
			</span>
		</div>
	</div>
)
