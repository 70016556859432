export enum TYPE {
	ID = 'ID',
	FIELD_ID = 'FIELD_ID',
	IS_REQUIRED = 'IS_REQUIRED',
	MULTIPLE = 'MULTIPLE',
	DEFAULT_VALUE = 'DEFAULT_VALUE',
	CODE = 'CODE',
	PROPERTY_TYPE = 'PROPERTY_TYPE',
	VALUE = 'VALUE',
	ELEMENT_CODE = 'ELEMENT_CODE',
	ELEMENT_ID = 'ELEMENT_ID',
}

export interface Value {
	id: string
	value: string
}

export enum RENDER_TYPE {
	TEXT = 'text',
	DATETIME = 'datetime',
	LIST_ENUM = 'enumeration',
	IBLOCK_ELEMENETS = 'iblock_list',
	CRM = 'crm',
	EMPLOYEE = 'employee',
}

export class FieldType {
	public readonly TYPE: string
	public readonly NAME: string
	public readonly FIELD_ID: string
	public readonly PROPERTY_TYPE: boolean | string
	public readonly CODE?: string
	public readonly USER_TYPE_SETTINGS?: object
	public readonly DISPLAY_VALUES_FORM?: object
	public readonly DEFAULT_VALUE: string | null

	private readonly IS_REQUIRED: boolean
	private readonly MULTIPLE: boolean

	/**
	 * string для PROPERTY_TYPE === false (стандартных полей инфоблока FILED)
	 * object {key: value} (пользовательских свойств PROPERTY)
	 */
	private VALUE: string | object | null = null

	private n: number = 0

	constructor(field: object, value: string | string[] | undefined) {
		Object.keys(field).forEach(key => (this[key] = field[key]))
		this[TYPE.IS_REQUIRED] = field[TYPE.IS_REQUIRED] === 'Y'
		this[TYPE.MULTIPLE] = field[TYPE.MULTIPLE] === 'Y'
		if (value !== undefined) {
			this[TYPE.VALUE] = value
		} else if (this.DEFAULT_VALUE !== null && this.DEFAULT_VALUE.length > 0) {
			this[TYPE.VALUE] =
				this.PROPERTY_TYPE === false ? this.DEFAULT_VALUE : { n0: this.DEFAULT_VALUE }
		} else if (this.DISPLAY_VALUES_FORM !== undefined && !this[TYPE.MULTIPLE]) {
			this[TYPE.VALUE] = { n0: `${Object.keys(this.DISPLAY_VALUES_FORM).shift()}` }
		}
	}

	public getValueForSave(): string | object | null {
		return this.PROPERTY_TYPE === false
			? this.VALUE
			: this.VALUE === null
			? { n0: this.TYPE === 'N' ? '0' : null }
			: { ...{}, ...(this.VALUE as object) }
	}

	public getCode(): string {
		return this.PROPERTY_TYPE === false
			? this.FIELD_ID
			: // tslint:disable-next-line:strict-type-predicates
			this.CODE !== undefined && this.CODE !== null && this.CODE.length > 0
			? `PROPERTY_${this.CODE}`
			: this.FIELD_ID
	}

	public isMultiple() {
		return this.MULTIPLE
	}

	public isRequired() {
		return this.IS_REQUIRED
	}

	public isEmpty(): boolean {
		return this.VALUE === null ? true : this.toString().length === 0
	}

	/**
	 * Возвращает ключ для нового значения
	 * в массиве значений множественного поля
	 */
	public nextIdx(): string {
		const keys = this.VALUE === null ? [] : Object.keys(this.VALUE)
		do {
			const idx = `n${this.n++}`
			if (keys.indexOf(idx) < 0) {
				return idx
			}
		} while (true)
	}

	public idx(): string {
		return `n${this.n}`
	}

	public setValue(value: string[] | Value) {
		if (this.PROPERTY_TYPE === false) {
			if (Array.isArray(value)) {
				const v = value.shift()
				this.VALUE = v === undefined ? null : v
			} else {
				this.VALUE = value.value
			}
		} else {
			if (Array.isArray(value)) {
				if (!this.isMultiple() && value.length > 1) {
					value.splice(1)
				}

				const keys = this.VALUE === null ? [] : Object.keys(this.VALUE)
				const values = this.getValue()
				value.forEach(v => {
					if (this.VALUE === null) {
						this.VALUE = {}
					}
					const idx = values.indexOf(v)
					if (idx === -1) {
						this.VALUE[this.nextIdx()] = v
					} else {
						delete keys[idx]
					}
				})

				keys.forEach(key => {
					if (this.VALUE !== null) {
						delete this.VALUE[key]
					}
				})
			} else {
				const newValue = value
				if (this.VALUE === null) {
					this.VALUE = { [newValue.id]: newValue.value }
				} else {
					this.VALUE[newValue.id] = newValue.value
				}
			}
		}
	}

	public toString(): string {
		return this.getValue().join(' | ')
	}

	public printable(): string {
		const value = this.getValue()
		return value.join(' | ')
	}

	public getRenderType(): RENDER_TYPE {
		switch (this.TYPE) {
			case 'ACTIVE_FROM':
			case 'ACTIVE_TO':
				return RENDER_TYPE.DATETIME
			case 'L':
				return RENDER_TYPE.LIST_ENUM
			case 'S:ECrm':
				return RENDER_TYPE.CRM
			case 'S:employee':
				return RENDER_TYPE.EMPLOYEE

			default:
				switch (this.PROPERTY_TYPE) {
					case 'E':
						return RENDER_TYPE.IBLOCK_ELEMENETS
					default:
						return RENDER_TYPE.TEXT
				}
		}
	}

	/**
	 * Возвращает массив значений как строки
	 */
	public getValue(): string[] {
		// Стандартное поле FIELD (не бывает множественным, хранится как значение)
		if (this.PROPERTY_TYPE === false) {
			return [this.VALUE as string]
		}
		// Пользовательское свойство PROPERTY_
		const value = this.VALUE
		if (value !== null) {
			return Object.keys(value).map(key => value[key])
		}
		return []
	}

	/**
	 * Возвращает массив объектов значений
	 * object {key: value}
	 */
	public getValues(): Value[] {
		// Пустое значение (нет значения)
		if (this.VALUE === null) {
			return [
				{
					id: this.idx(),
					value: this.DEFAULT_VALUE === null ? '' : this.DEFAULT_VALUE,
				},
			]
		}
		// Стандартное поле FIELD (не бывает множественным, хранится как значение)
		if (this.PROPERTY_TYPE === false) {
			return [
				{
					id: this.idx(),
					value: this.VALUE as string,
				},
			]
		}
		// Пользовательское свойство PROPERTY_
		const value = this.VALUE
		// tslint:disable-next-line:strict-type-predicates
		if (value !== null) {
			return Object.keys(value).map(key => ({
				id: key,
				value: value[key],
			}))
		}
		return []
	}
}
