import { ActionHelper, ActionItem, RefItem, TaskItem } from './helper'

export const TASK_REFS: ActionItem[] = [
	{
		iblockCode: 'ref_tasks',
		title: 'Дополнительные задачи',
		uf: 'UF_TASKS_ADDITIONAL',
	},
	{
		iblockCode: 'ref_market',
		title: 'Маркетинговая активность',
		uf: 'UF_TASKS_MARKET',
	},
	{
		iblockCode: 'ref_src_brands',
		title: 'Источники потенциала брендов',
		uf: 'UF_TASKS_BRAND',
	},
]

export default class TaskForm extends ActionHelper {
	public getItems(): ActionItem[] {
		return TASK_REFS
	}

	public getFilter(iblockCode: string): Promise<object> {
		return new Promise(resolve => resolve({}))
	}

	public handleSelectedItems(iblockCode: string, items: RefItem[]): void {
		const deadline = this.props.entity.getValue('ACTIVE_FROM')

		const batch = items.map(item => {
			const taskParams: TaskItem = {
				DEADLINE: deadline,
				DESCRIPTION:
					item.PREVIEW_TEXT !== null &&
					item.PREVIEW_TEXT !== undefined &&
					item.PREVIEW_TEXT.length > 0
						? item.PREVIEW_TEXT
						: item.DETAIL_TEXT !== null &&
						  item.DETAIL_TEXT !== undefined &&
						  item.DETAIL_TEXT.length > 0
						? item.DETAIL_TEXT
						: '',
				RESPONSIBLE_ID: this.props.entity.getValue('PROPERTY_RESPONSIBLE'),
				TAGS: item.IBLOCK_SECTION_NAME === undefined ? [] : [item.IBLOCK_SECTION_NAME],
				TITLE: `[Визит №${this.props.entity.getID()}] ${item.NAME}`,
				UF_CRM_TASK: [`CO_${this.props.entity.getValue('PROPERTY_SALON')}`],
				UF_TASK_VISIT: this.props.entity.getID(),
			}

			const actionRef = TASK_REFS.find(a => a.iblockCode === iblockCode)
			if (actionRef !== undefined) {
				taskParams[actionRef.uf] = [item.ID]
			}

			const responsibles = item.PROPERTY_RESPONSIBLE
			if (responsibles.length > 0) {
				taskParams.RESPONSIBLE_ID = responsibles.shift()
				if (responsibles.length > 0) {
					taskParams.ACCOMPLICES = responsibles
				}
			}

			return ['task.item.add', [taskParams]]
		})

		global.console.info(`handleSelectedItems [${iblockCode}]`, [items, batch])

		window.BX24.callBatch(batch, (batchResult: BX24.ResultObject[]) => {
			const errors = batchResult.filter(result => {
				if (result.error() !== undefined) {
					global.console.error(result.error())
					return true
				}
				return false
			})

			if (errors.length === 0) {
				this.props.apply()
			}
		})
	}
}
