import * as moment from 'moment'
import { ActionHelper, ActionItem, RefItem } from './helper'

export const ACTION_REFS: ActionItem[] = [
	{
		iblockCode: 'event_new_product',
		title: 'Новинка',
		uf: 'UF_TASK_EVENT_NEW',
	},
	{
		iblockCode: 'event_promo',
		title: 'Акция/промо',
		uf: 'UF_TASK_EVENT_PROMO'
	},
	{
		iblockCode: 'event_self',
		title: 'Аутентика',
		uf: 'UF_TASK_EVENT_SELF'
	},
	{
		iblockCode: 'event_salon',
		title: 'Салон',
		uf: 'UF_TASK_EVENT_SALON'
	}
]

interface EventRequest {
	SALON_ID: string
	IBLOCK_CODE: string
	PROPERTY_SALON: string
}

export default class ActionForm extends ActionHelper {

	public getItems(): ActionItem[] {
		return ACTION_REFS
	}

	/**
	 * Подготовка фильтра с учетом типа события (сложный фильтр на backend)
	 */
	public getFilter(iblockCode: string): Promise<object> {
		return new Promise((resolve) => {
			const filter = {
				DATE_ACTIVE_FROM_datesel: 'RANGE',
				DATE_ACTIVE_FROM_from: moment().format('DD.MM.YYYY 00:00:00'),
				DATE_ACTIVE_FROM_to: ''
			}

			const propertySalon = this.props.entity.get('PROPERTY_SALON')
			if (propertySalon !== undefined) {

				const params: EventRequest = {
					IBLOCK_CODE: iblockCode,
					PROPERTY_SALON: JSON.stringify({COMPANY: [this.props.entity.getValue('PROPERTY_SALON')]}),
					SALON_ID: this.props.entity.getValue('PROPERTY_SALON')
				}

				window.BX24.callMethod(
					'authentica.visit.events.filter',
					params,
					(result: BX24.ResultObject) => {
						if (result.error()) {
							global.console.error('Ошибка запроса данных фильтра', result.error())
						} else {
							global.console.info(`Данные для фильтра [${iblockCode}]`, result.data())
							resolve({...filter, ...result.data()})
						}
					}
				)
			}
		})
	}

	/**
	 * Созданием задач для событий на организатора
	 */
	public handleSelectedItems(iblockCode: string, items: RefItem[]): void {
		global.console.info('handleSelectedItems', items)
		const deadline = this.props.entity.getValue('ACTIVE_FROM')

		const batch = items.map((item) => {
			const taskParams = {
				DEADLINE: deadline,
				DESCRIPTION: item.PREVIEW_TEXT,
				RESPONSIBLE_ID: this.props.entity.getValue('PROPERTY_RESPONSIBLE'),
				TITLE: item.NAME,
				UF_CRM_TASK: [`CO_${this.props.entity.getValue('PROPERTY_SALON')}`],
				UF_TASK_VISIT: this.props.entity.getID(),
			}
			const actionRef = ACTION_REFS.find((a) => a.iblockCode === iblockCode)
			if (actionRef !== undefined) {
				taskParams[actionRef.uf] = item.ID
			}
			return ['task.item.add', [taskParams]]
		})

		window.BX24.callBatch(batch, (batchResult: BX24.ResultObject[]) => {
			const errors = batchResult.filter((result) => {
				if (result.error() !== undefined) {
					global.console.error(result.error())
					return true
				}
				return false
			})

			if (errors.length === 0) {
				this.props.apply()
			}
		})
	}

}
