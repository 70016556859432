import { FieldType, TYPE } from './fieldtype'

const IGNORE_FILEDS_ON_SAVE: string[] = ['DETAIL_PICTURE', 'PREVIEW_PICTURE']

export class Item {
    private id: number
    private code: string
    private fields: FieldType[]
    private IBLOCK_CODE: string
    private IBLOCK_TYPE_ID: string

    public static uniqid() {
        const charsList = '0123456789abcdefghijklmnopqrstuvwxyz'
        let s = ''
        for (let i: number = 0; i < 32; i++) {
            s += charsList[Math.round(Math.random() * (charsList.length - 1))]
        }
        return s
    }

	/**
	 * Загружает элемент из списка
	 */
    public static load(id: number, iBlockCode: string, iBlockTypeId: string): Promise<Item> {
        return Item.loadEx('ELEMENT_ID', id, iBlockCode, iBlockTypeId)
    }

	/**
	 * Создает элемент из предварительно загруженных данных
	 */
    public static create(
        types: object,
        item: object,
        iBlockCode: string,
        iBlockTypeId: string
    ): Item {
        return new Item(item, types, iBlockCode, iBlockTypeId, undefined)
    }

	/**
	 * Загружает элемент из списка по ELEMENT_ID или ELEMENT_CODE
	 */
    public static loadEx(
        by: 'ELEMENT_ID' | 'ELEMENT_CODE',
        id: number | string,
        iBlockCode: string,
        iBlockTypeId: string
    ): Promise<Item> {
        return new Promise((resolve, reject) => {
            window.BX24.callBatch(
                [
                    [
                        'lists.field.get',
                        {
                            IBLOCK_CODE: iBlockCode,
                            IBLOCK_TYPE_ID: iBlockTypeId,
                        },
                    ],
                    [
                        'lists.element.get',
                        {
                            [by]: id,
                            IBLOCK_CODE: iBlockCode,
                            IBLOCK_TYPE_ID: iBlockTypeId,
                        },
                    ],
                ],
                (result: BX24.BatchResult) => {
                    const errors = result.filter(r => !!r.error())
                    if (errors.length === 0) {
                        const types = result[0].data()
                        const items = result[1].data()
                        // tslint:disable-next-line:strict-type-predicates
                        if (types !== undefined && items !== undefined) {
                            resolve(new Item(items[0], types, iBlockCode, iBlockTypeId, undefined))
                        }
                    } else {
                        reject(errors.map(r => r.error()))
                    }
                }
            )
        })
    }

	/**
	 * Создает новый элемент
	 */
    public static init(
        iBlockCode: string,
        iBlockTypeId: string,
        defaultValues: object | undefined
    ): Promise<Item> {
        return new Promise((resolve, reject) => {
            window.BX24.callMethod(
                'lists.field.get',
                {
                    IBLOCK_CODE: iBlockCode,
                    IBLOCK_TYPE_ID: iBlockTypeId,
                },
                (result: BX24.ResultObject) => {
                    if (result.error()) {
                        reject(result.error())
                    } else {
                        const types = result.data()
                        // tslint:disable-next-line:strict-type-predicates
                        if (types !== undefined) {
                            const fields = {
                                [TYPE.ID]: '0',
                                [TYPE.ELEMENT_CODE]: Item.uniqid(),
                            }

                            resolve(new Item(fields, types, iBlockCode, iBlockTypeId, defaultValues))
                        }
                    }
                }
            )
        })
    }

    public isValid(): boolean {
        const errors = this.fields.filter(f => f.isRequired() && f.isEmpty())
        if (errors.length > 0) {
            global.console.error(`Required field with empty values`, errors)
        }
        return errors.length === 0
    }

	/**
	 * Сохраняет элемент (создает или обновляет)
	 */
    public save(ignoredFields: string[] = []): Promise<number> {
        const isNew = this.id === 0
        const fields: object = {}
        this.fields
            .filter(field => IGNORE_FILEDS_ON_SAVE.indexOf(field.FIELD_ID) < 0)
            .filter(field => ignoredFields.indexOf(field.FIELD_ID) < 0)
            .forEach(field => {
                fields[field.FIELD_ID] = field.getValueForSave()
            })

        const params: BX24.ListsParams = {
            FIELDS: fields,
            IBLOCK_CODE: this.IBLOCK_CODE,
            IBLOCK_TYPE_ID: this.IBLOCK_TYPE_ID,
        }

        return new Promise<number>((resolve, reject) => {
            if (isNew) {
                params[TYPE.ELEMENT_CODE] = this.code

                window.BX24.callMethod('lists.element.add', params, (result: BX24.ResultObject) => {
                    const error = result.error()
                    if (error !== undefined) {
                        reject(`[${error.ex.error}] ${error.ex.error_description}`)
                    } else {
                        this.id = +(result.data() as string)
                        resolve(this.id)
                    }
                })
            } else {
                params[TYPE.ELEMENT_ID] = this.id

                window.BX24.callMethod(
                    'lists.element.get',
                    {
                        ELEMENT_ID: this.id,
                        IBLOCK_CODE: this.IBLOCK_CODE,
                        IBLOCK_TYPE_ID: this.IBLOCK_TYPE_ID,
                    },
                    (resultGet: BX24.Result) => {
                        const preData = resultGet.data().shift()
                        if (preData !== undefined) {
                            ignoredFields.forEach(field => {
                                if (preData.hasOwnProperty(field)) {
                                    params.FIELDS[field] = preData[field]
                                }
                            })
                        }

                        window.BX24.callMethod('lists.element.update', params, (result: BX24.ResultObject) => {
                            const error = result.error()
                            if (error !== undefined) {
                                reject(`[${error.ex.error}] ${error.ex.error_description}`)
                            } else {
                                resolve(this.id)
                            }
                        })
                    }
                )
            }
        })
    }

    public getID(): number {
        return this.id
    }

    public get(fieldID: string): FieldType | undefined {
        const _fieldID = fieldID.split('_')
        const code = _fieldID.shift() === 'PROPERTY' ? _fieldID.join('_') : undefined
        return this.fields.find(
            field =>
                field[TYPE.FIELD_ID] === fieldID ||
                (code !== undefined && field.hasOwnProperty(TYPE.CODE) && field.CODE === code)
        )
    }

    public getValue(fieldID: string): string {
        const field = this.get(fieldID)
        if (field === undefined) {
            return ''
        }
        const value = field.getValue()
        // tslint:disable-next-line:strict-type-predicates
        return value !== undefined && value.length > 0 ? value[0] : ''
    }

    public getValues(fieldID: string): string[] {
        const field = this.get(fieldID)
        if (field === undefined) {
            return []
        }
        const value = field.getValue()
        // tslint:disable-next-line:strict-type-predicates
        return value !== undefined ? value : []
    }

    private constructor(
        item: object,
        types: object,
        iBlockCode: string,
        iBlockTypeId: string,
        defaultValues: object | undefined
    ) {
        this.id = +item[TYPE.ID]
        this.code = item[TYPE.ELEMENT_CODE]
        this.IBLOCK_CODE = iBlockCode
        this.IBLOCK_TYPE_ID = iBlockTypeId
        this.fields = Object.keys(types).map(fieldID => new FieldType(types[fieldID], item[fieldID]))

        if (defaultValues !== undefined) {
            // Инициализация начальным значениями
            Object.keys(defaultValues).forEach(fieldCode => {
                const field = this.get(fieldCode)
                if (field !== undefined) {
                    field.setValue(
                        field.PROPERTY_TYPE === false
                            ? defaultValues[fieldCode]
                            : Array.isArray(defaultValues[fieldCode])
                                ? defaultValues[fieldCode]
                                : [defaultValues[fieldCode]]
                    )
                }
            })
        }
    }
}
