import update from 'immutability-helper'
import * as Logger from 'js-logger'
import * as moment from 'moment'
import * as React from 'react'
import {
    ACTION_REFS,
    ActionForm,
    CommentForm,
    ControlForm,
    OrderForm,
    TASK_REFS,
    TaskForm,
    TimelineCategory,
} from '../views/action'
import * as Entity from '../views/entity'
import SectionNote from '../views/section-note'
import Merchandise from './merchadise'
// @ts-ignore
import WeekCalendar from 'react-week-calendar'
import 'react-week-calendar/dist/style.css'

Logger.useDefaults()

interface NewAction {
    key: string
    title: string
    enabled: boolean
    component(entity: Entity.Item): React.ReactNode
}

interface UserItem {
    ID: string
    NAME: string
    LAST_NAME: string
    EMAIL: string
    SECOND_NAME: string
    PERSONAL_PHOTO: string
    WORK_POSITION: string
}

interface LogItem {
    name: string
    value_old?: string
    value_new: string
}

interface VisitCardProps {
    id: number
    companyId: string | null
    isWithTitle: boolean
    back?(): void
}

const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm:ss'

const TIMELINES_REF: TimelineCategory[] = [
    {
        color: 'orange',
        key: 'business',
        title: 'Бизнес',
        uf: ['UF_TASK_EVENT_PROMO'],
    },
    {
        color: 'green',
        key: 'knowledge',
        title: 'Знания',
        uf: ['UF_TASK_EVENT_NEW'],
    },
    {
        color: 'lightgreen',
        key: 'relationships',
        title: 'Отношения',
        uf: ['UF_TASK_EVENT_SALON', 'UF_TASK_EVENT_SELF'],
    },
]

enum TimelineItemType {
    TASK,
    COMMENT,
}

interface TimelineItem {
    ID: string
    TITLE: string
    DESCRIPTION: string
    DEADLINE: string
    RESPONSIBLE_ID: string
    CREATED_DATE: string
    CLOSED_DATE: string
    date: moment.Moment
    TAGS?: string[]
    isClosed: boolean
    timeline: string
    header: string
    type: TimelineItemType
}

interface AccessibilityItem {
    USER_ID: string
    USER_NAME: string
    ACCESSIBILITY: string // busy
    DATE_FROM: moment.Moment
    DATE_TO: moment.Moment
    ID: string
    NAME: string
    TZ_FROM: string
    TZ_TO: string
}

interface VisitCardState {
    action: NewAction | null
    isNew: boolean
    id: number
    item: Entity.Item | null
    changes: string[]
    timeline: TimelineItem[] | null
    salon?: string
    user?: BX24.UserProfile
    contacts: Entity.SelectValue[] | undefined
    merchandise: string[] | null
    newStatus: string | null
    log: LogItem[]
    statusEnabled: string[]
    accessibility: AccessibilityItem[]
    isAccessibilityVisible: boolean
}

interface TimelineProps {
    items: TimelineItem[]
    title: string
    category: string
    color: string
    last?: boolean
}

const TimelineSection: React.FunctionComponent<TimelineProps> = ({
    title,
    category,
    color,
    items,
    last,
    children,
}) => (
    <Entity.StreamSection title={title} color={color} last={last}>
        {children}
        {items
            .filter((item, i) => item.timeline === category)
            .map(item => (
                <Entity.StreamSectionEvent
                    key={`${category}#${item.ID}`}
                    icon={item.type === TimelineItemType.TASK ? 'task' : 'info'}
                    isClosed={item.isClosed}
                    color={color}
                    header={item.header}
                    date={item.date}
                    title={item.type === TimelineItemType.TASK ? item.TITLE : item.DESCRIPTION}
                    onClick={
                        item.type === TimelineItemType.TASK
                            ? () => {
                                  window.top.postMessage(
                                      `CustomVisitHelper:openSidePanel:${JSON.stringify({
                                          url: `/company/personal/user/${item.RESPONSIBLE_ID}}/tasks/task/view/${item.ID}/`,
                                      })}`,
                                      `https://${window.BX24.getDomain()}`
                                  )
                              }
                            : undefined
                    }
                />
            ))}
        <SectionNote section={category} last={last} />
    </Entity.StreamSection>
)

export default class VisitCard extends React.Component<VisitCardProps, VisitCardState> {
    constructor(props: VisitCardProps) {
        super(props)
        this.state = {
            action: null,
            changes: [],
            contacts: undefined,
            id: this.props.id,
            isNew: this.props.id === 0,
            item: null,
            merchandise: null,
            newStatus: null,
            timeline: null,
            log: [],
            statusEnabled: this.props.id === 0 ? ['21519'] : [], // Для нового доступен только "На согласовании",
            accessibility: [],
            isAccessibilityVisible: false,
        }
        this.changeField = this.changeField.bind(this)
        this.cancelItemEdit = this.cancelItemEdit.bind(this)
        this.saveItem = this.saveItem.bind(this)
        this.actionCancel = this.actionCancel.bind(this)
        this.actionApply = this.actionApply.bind(this)
        this.loadTimeline = this.loadTimeline.bind(this)
        this.loadAccessibilityUsers = this.loadAccessibilityUsers.bind(this)
        this.proceedAutoEvents = this.proceedAutoEvents.bind(this)
        this.handleIntervalSelect = this.handleIntervalSelect.bind(this)

        this.initEventListener()
    }

    public componentDidMount() {
        window.BX24.callMethod('profile', {}, (result: BX24.ResultObject) => {
            const profile = result.data() as BX24.UserProfile
            const entityItem = this.state.isNew
                ? Entity.Item.init('visits', 'lists', {
                      ACTIVE_FROM: [''],
                      NAME: ['Визит'],
                      PROPERTY_RESPONSIBLE: profile.ID,
                      PROPERTY_SALON: this.props.companyId === null ? [] : this.props.companyId,
                  })
                : Entity.Item.load(this.state.id, 'visits', 'lists')

            entityItem
                .then((entity: Entity.Item) => {
                    Logger.info(`Визит ${this.state.isNew ? 'новый' : '#' + this.state.id}`, entity)

                    if (this.props.isWithTitle) {
                        window.BX24.setTitle(
                            this.state.isNew ? 'Назначить визит' : `Визит №${this.state.id}`
                        )
                    }

                    const _salon = entity.get('PROPERTY_SALON')
                    const salonID = _salon === undefined ? undefined : _salon.getValue().shift()
                    this.setState(
                        update(this.state, {
                            $merge: {
                                contacts: undefined,
                                id: entity.getID(),
                                item: salonID === undefined ? entity : null,
                                salon: salonID,
                                user: profile,
                                isAccessibilityVisible: false,
                            },
                        }),
                        () => {
                            if (salonID !== undefined) {
                                this.loadSalonContact(salonID, this.proceedAutoEvents, entity)
                            } else {
                                this.proceedAutoEvents()
                            }
                        }
                    )
                })
                .catch(errors => {
                    Logger.error(`Ошибка загрузки данных #${this.state.id}`, errors)
                })
        })
    }

    /**
     * Подгружаем контакты салона
     *
     * @param salonID
     * @param callback
     * @param entity
     */
    private loadSalonContact(salonID: string, callback?: () => void, entity?: Entity.Item) {
        window.BX24.callMethod(
            'crm.contact.list',
            {
                filter: { COMPANY_ID: salonID },
                order: {
                    LAST_NAME: 'asc',
                    NAME: 'asc',
                },
                select: ['ID', 'NAME', 'LAST_NAME', 'POST'],
            },
            (resultContacts: BX24.Result) => {
                if (resultContacts.error()) {
                    Logger.error('Ошибка загрузки контактов салона', resultContacts.error())
                } else {
                    const updates = {
                        contacts: resultContacts.data().map((contact: BX24.Entity.CRM.Contact) => ({
                            label: `${contact.LAST_NAME} ${contact.NAME}`,
                            value: `${contact.ID}`,
                        })),
                    }

                    if (entity !== undefined) {
                        updates[`item`] = entity
                    }

                    this.setState(
                        update(this.state, {
                            $merge: updates,
                        }),
                        () => {
                            if (callback !== undefined) {
                                callback()
                            }
                        }
                    )
                }
            }
        )
    }

    public actionCancel() {
        this.setState(update(this.state, { $merge: { action: null } }))
    }

    public actionApply() {
        this.setState(update(this.state, { $merge: { action: null } }), () => {
            this.loadTimeline()
        })
    }

    /**
     * Загружает данные задач, контроль мерчендайзинга и комментарии для timeline
     * и классифицирует их по типам
     */
    public loadTimeline() {
        if (this.state.isNew) {
            this.setState(update(this.state, { $merge: { timeline: [] } }))
        } else {
            window.BX24.callBatch(
                [
                    [
                        // 0
                        'authentica.tasks.list',
                        {
                            // Задачи визита
                            FILTER: {
                                CHECK_PERMISSIONS: 'N',
                                UF_TASK_VISIT: this.state.id,
                            },
                            ORDER: {
                                CLOSED_DATE: 'desc',
                                CREATED_DATE: 'desc',
                                STATUS: 'asc',
                            },
                        },
                    ],
                    [
                        // 1
                        'entity.item.get',
                        {
                            // Комментарии визита
                            ENTITY: 'visit_comments',
                            FILTER: {
                                XML_ID: this.state.id,
                            },
                            SORT: {
                                CODE: 'ASC',
                                SORT: 'ASC',
                                DATE_CREATE: 'DESC',
                                ID: 'DESC',
                            },
                        },
                    ],
                    [
                        // 2
                        'authentica.visit.control.list',
                        {
                            VISIT_ID: this.state.id,
                        },
                    ],
                    [
                        // 3
                        'authentica.visit.status.enabled',
                        {
                            VISIT_ID: this.state.id,
                        },
                    ],
                ],
                (batchResult: BX24.BatchResult) => {
                    const result = batchResult[0]
                    if (result.error()) {
                        Logger.error('Ошибка загрузки задач для timeline', result.error())
                        return
                    }

                    const items = result.data().map((task: TimelineItem) => {
                        const taskTimeline = TIMELINES_REF.find(category => {
                            const uf = category.uf.find(
                                (item: string) =>
                                    task[item] !== null &&
                                    task[item] !== false &&
                                    (!Array.isArray(task[item]) || task[item].length > 0)
                            )
                            if (uf !== undefined) {
                                return true
                            }
                            if (task.TAGS !== undefined && task.TAGS.indexOf(category.title) > -1) {
                                return true
                            }
                            return false
                        })

                        const action = ACTION_REFS.find(
                            a => task.hasOwnProperty(a.uf) && task[a.uf] !== null
                        )
                        const taskUf = TASK_REFS.find(
                            a => task.hasOwnProperty(a.uf) && task[a.uf] !== null
                        )

                        return {
                            date: moment(task.CREATED_DATE, 'DD.MM.YYYY hh:mm:ss'),
                            header:
                                // tslint:disable-next-line:strict-type-predicates
                                action === undefined || action === null
                                    ? // tslint:disable-next-line:strict-type-predicates
                                      taskUf === undefined || taskUf === null
                                        ? 'Задача'
                                        : taskUf.title
                                    : action.title,
                            // tslint:disable-next-line:strict-type-predicates
                            isClosed: task.CLOSED_DATE !== null && task.CLOSED_DATE.length > 0,
                            timeline: taskTimeline === undefined ? 'business' : taskTimeline.key,
                            type: TimelineItemType.TASK,
                            ...task,
                        }
                    })

                    const resultComment = batchResult[1]
                    if (resultComment.error()) {
                        Logger.error('Ошибка загрузки комментариев', resultComment.error())
                    } else {
                        resultComment.data().forEach((comment: BX24.Entity.IBlock.Element) => {
                            items.push({
                                CLOSED_DATE: '',
                                CREATED_DATE: comment.DATE_CREATE,
                                DEADLINE: '',
                                DESCRIPTION: comment.PREVIEW_TEXT,
                                ID: comment.ID,
                                RESPONSIBLE_ID: comment.CREATED_BY,
                                TITLE: comment.NAME,
                                date: moment(comment.DATE_CREATE),
                                header: `${comment.NAME}`,
                                isClosed: true,
                                timeline: comment.CODE,
                                type: TimelineItemType.COMMENT,
                            })
                        })
                    }

                    Logger.info('timeline', items)
                    Logger.info('enabled status list', batchResult[3].data())
                    this.setState(
                        update(this.state, {
                            $merge: {
                                timeline: items,
                                statusEnabled: batchResult[3].data() as string[],
                            },
                        }),
                        () => {
                            const resultControl = batchResult[2]
                            if (resultControl.error()) {
                                Logger.error(
                                    'Ошибка загрузки контроля мерчендайзинга',
                                    resultControl.error()
                                )
                            } else {
                                const controlItems = resultControl.data() as string[]
                                Logger.info('Контроль мерчендайзинга', controlItems)
                                this.setState(
                                    update(this.state, {
                                        $merge: { merchandise: controlItems },
                                    })
                                )
                            }
                        }
                    )
                }
            )
        }
    }

    public getNewActions(): React.ReactNode[] {
        const ACTIONS: NewAction[] = [
            {
                component: (entity: Entity.Item) => (
                    <CommentForm
                        apply={this.actionApply}
                        cancel={this.actionCancel}
                        categories={TIMELINES_REF}
                        user={this.state.user}
                        visit={this.state.id}
                        isPlane={
                            this.state.item === null ||
                            ['21520', '21521'].indexOf(
                                this.state.item.getValue('PROPERTY_STATUS')
                            ) < 0
                        }
                    />
                ),
                enabled: true,
                key: 'comment',
                title: 'Цель визита',
            },
            {
                component: (entity: Entity.Item) => (
                    <ActionForm entity={entity} apply={this.actionApply} cancel={this.actionCancel}>
                        <div style={{ display: 'none' }}>
                            <button onClick={this.proceedAutoEvents} className="ui-btn ui-btn-xs">
                                Добавить все актуальные события
                            </button>
                        </div>
                    </ActionForm>
                ),
                enabled: true,
                key: 'event',
                title: 'Событие',
            },
            {
                component: (entity: Entity.Item) => (
                    <TaskForm entity={entity} apply={this.actionApply} cancel={this.actionCancel}>
                        <div>
                            {TIMELINES_REF.map(item => (
                                <button
                                    onClick={() =>
                                        window.top.postMessage(
                                            `CustomVisitHelper:openNewTask:${JSON.stringify({
                                                salonID: this.state.salon,
                                                tags: [item.title],
                                                title: '',
                                                userID:
                                                    this.state.user === undefined
                                                        ? null
                                                        : this.state.user.ID,
                                                visitID: this.state.id,
                                            })}`,
                                            `https://${window.BX24.getDomain()}`
                                        )
                                    }
                                    className="ui-btn ui-btn-xs"
                                    key={item.key}
                                    style={{
                                        backgroundColor: item.color,
                                        marginRight: '10px',
                                    }}
                                >
                                    {item.title}
                                </button>
                            ))}
                        </div>
                    </TaskForm>
                ),
                enabled: true,
                key: 'task',
                title: 'Задача',
            },
            {
                component: (entity: Entity.Item) => (
                    <OrderForm
                        cancel={this.actionCancel}
                        salon={this.state.salon}
                        visit={this.state.id}
                    />
                ),
                enabled: true,
                key: 'order',
                title: 'Заказ',
            },
            {
                component: (entity: Entity.Item) => (
                    <ControlForm
                        apply={this.actionApply}
                        cancel={this.actionCancel}
                        salon={this.state.salon}
                        visit={this.state.id}
                    />
                ),
                enabled:
                    !this.state.isNew &&
                    this.state.merchandise !== null &&
                    this.state.merchandise.length === 0,
                key: 'control',
                title: 'Контроль',
            },
        ]

        return ACTIONS.filter(a => a.enabled).map(a => (
            <Entity.StreamSectionNewAction
                key={a.key}
                active={this.state.action !== null && this.state.action.key === a.key}
                onClick={() => {
                    this.setState(update(this.state, { $merge: { action: a } }))
                }}
            >
                {a.title}
            </Entity.StreamSectionNewAction>
        ))
    }

    public render() {
        const {
            action,
            item,
            timeline,
            merchandise,
            statusEnabled,
            isAccessibilityVisible,
            accessibility,
        } = this.state
        return item === null ? (
            <Entity.Loading>Загрузка данных...</Entity.Loading>
        ) : (
            <div>
                <Entity.Card
                    title={
                        this.props.isWithTitle
                            ? null
                            : this.state.isNew
                            ? 'Назначить визит'
                            : `Визит №${this.state.id}`
                    }
                    isDefaultEdit={this.state.isNew}
                    back={this.props.back}
                    onSave={this.saveItem}
                    onCancel={this.cancelItemEdit}
                    onChange={this.changeField}
                    isHideEmpty={true}
                >
                    <Entity.CardContainer>
                        <Entity.CardWidget title="Когда">
                            <Entity.Field field={item.get('ACTIVE_FROM')} />
                            <Entity.Field field={item.get('ACTIVE_TO')} />
                            {isAccessibilityVisible && accessibility.length > 0 && (
                                <div className="crm-entity-widget-content-block crm-entity-widget-content-block-field-text">
                                    <div className="crm-entity-widget-content-block-title">
                                        <span className="crm-entity-widget-content-block-title-text">
                                            Занятость участников
                                        </span>
                                    </div>
                                    <div className="crm-entity-widget-content-block-inner">
                                        <WeekCalendar // https://github.com/birik/react-week-calendar
                                            firstDay={moment(
                                                item.getValue('ACTIVE_FROM'),
                                                DATETIME_FORMAT
                                            )}
                                            numberOfDays={1}
                                            scaleHeaderTitle="Время"
                                            dayFormat="DD.MM"
                                            startTime={moment({ h: 8, m: 0 })}
                                            endTime={moment({ h: 20, m: 0 })}
                                            scaleUnit={30}
                                            scaleFormat="HH:mm"
                                            cellHeight={25}
                                            selectedIntervals={accessibility.map(
                                                (acc: AccessibilityItem) => ({
                                                    uid: acc.ID,
                                                    start: acc.DATE_FROM,
                                                    end: acc.DATE_TO,
                                                    value: `${acc.USER_NAME}: ${acc.NAME}`,
                                                })
                                            )}
                                            onIntervalSelect={this.handleIntervalSelect}
                                            useModal={false}
                                        />
                                    </div>
                                </div>
                            )}
                            <Entity.Field
                                field={item.get('PROPERTY_STATUS')}
                                filter={id => statusEnabled.indexOf(id) > -1}
                            />
                        </Entity.CardWidget>
                        <Entity.CardWidget title="Зачем">
                            <Entity.Field field={item.get('PROPERTY_FORMAT')} />
                            <Entity.Field field={item.get('DETAIL_TEXT')} />
                        </Entity.CardWidget>
                        <Entity.CardWidget title="С кем">
                            <Entity.Field
                                field={item.get('PROPERTY_SALON')}
                                readonly={this.props.companyId !== null}
                            />
                            <Entity.Field
                                defaultValues={this.state.contacts}
                                field={item.get('PROPERTY_SALON_RESPONSIBLE')}
                            />
                            <Entity.Field
                                defaultValues={this.state.contacts}
                                field={item.get('PROPERTY_SALON_MEMBERS')}
                            />
                        </Entity.CardWidget>
                        <Entity.CardWidget title="От нас">
                            <Entity.Field field={item.get('PROPERTY_RESPONSIBLE')} />
                            <Entity.Field field={item.get('PROPERTY_MEMBERS')} />
                        </Entity.CardWidget>
                    </Entity.CardContainer>
                </Entity.Card>
                {this.state.isNew && this.props.back !== undefined ? null : (
                    <Entity.StreamContainer>
                        <Entity.StreamSectionNew header={this.getNewActions()}>
                            {action !== null && action.component(item)}
                        </Entity.StreamSectionNew>
                        {timeline === null ? (
                            this.state.isNew ? null : (
                                <Entity.Loading>Загрузка данных...</Entity.Loading>
                            )
                        ) : (
                            <div>
                                {TIMELINES_REF.map((category, idx) => (
                                    <TimelineSection
                                        key={category.key}
                                        category={category.key}
                                        items={timeline}
                                        title={category.title}
                                        color={category.color}
                                        last={true}
                                    >
                                        {category.key === 'business' &&
                                            merchandise !== null &&
                                            merchandise.length > 0 && (
                                                <div className="crm-entity-stream-section">
                                                    <div
                                                        className="crm-entity-stream-section-icon crm-entity-stream-section-icon-crmForm"
                                                        style={{
                                                            backgroundColor: category.color,
                                                        }}
                                                    />
                                                    <div className="crm-entity-stream-section-content">
                                                        <div className="crm-entity-stream-content-event">
                                                            <div className="crm-entity-stream-content-header">
                                                                <span className="crm-entity-stream-content-event-title">
                                                                    Контроль мерчендайзинга и
                                                                    ассортимента
                                                                </span>
                                                                <div className="crm-entity-stream-content-detail">
                                                                    {merchandise.map(
                                                                        merchandiseID => (
                                                                            <Merchandise
                                                                                id={+merchandiseID}
                                                                                key={merchandiseID}
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </TimelineSection>
                                ))}
                            </div>
                        )}
                    </Entity.StreamContainer>
                )}
            </div>
        )
    }

    /**
     * Изменяет значения полей даты начала и окончания визита из планировщика
     */
    public handleIntervalSelect(
        newIntervals: Array<{ end: moment.Moment; start: moment.Moment }>
    ): void {
        const { item } = this.state
        if (item === null || newIntervals.length === 0) {
            return
        }

        newIntervals.forEach(interval => {
            const from = item.get('ACTIVE_FROM')
            if (from !== undefined) {
                const oldValue = item.getValue('ACTIVE_FROM')
                from.setValue([interval.start.format(DATETIME_FORMAT)])
                this.changeField(from.getCode(), from.toString(), oldValue)
            }
            const to = item.get('ACTIVE_TO')
            if (to !== undefined) {
                const oldValue = item.getValue('ACTIVE_TO')
                to.setValue([interval.end.format(DATETIME_FORMAT)])
                this.changeField(to.getCode(), to.toString(), oldValue)
            }
        })
    }

    /**
     * Загружает занятость организатора и участников
     */
    private loadAccessibilityUsers(): void {
        const { item } = this.state
        if (item === null) {
            return
        }

        const users: string[] = item
            .getValues('PROPERTY_RESPONSIBLE')
            .concat(item.getValues('PROPERTY_MEMBERS'))

        const visitFrom = moment(item.getValue('ACTIVE_FROM'), DATETIME_FORMAT)
        const visitTo = moment(item.getValue('ACTIVE_TO'), DATETIME_FORMAT)

        const batch: BX24.BatchItem[] = [
            ['user.get', { ID: users }],
            [
                'calendar.accessibility.get',
                {
                    from: visitFrom.format('YYYY-MM-DD'),
                    to: visitTo.format('YYYY-MM-DD'),
                    users,
                },
            ],
        ]

        const activity = item.getValue('PROPERTY_ACTIVITY')
        const isActivity = activity.length > 0

        if (isActivity) {
            batch.push(['crm.activity.get', { ID: item.getValue('PROPERTY_ACTIVITY') }])
        }

        window.BX24.callBatch(batch, (result: BX24.BatchResult) => {
            if (result[0].error()) {
                global.console.error('Ошибка получения пользователей', result[0].error())
            } else if (result[1].error()) {
                global.console.error('Ошибка получения занятости', result[1].error())
            } else {
                const userItems: UserItem[] = result[0].data() as UserItem[]
                const accessibility: AccessibilityItem[] = []
                let isConflict = false
                Object.keys(result[1].data()).forEach(userID => {
                    result[1].data()[userID].forEach((acc: AccessibilityItem) => {
                        const dateFrom = moment(acc.DATE_FROM, DATETIME_FORMAT)
                        const dateTo = moment(acc.DATE_TO, DATETIME_FORMAT)

                        if (isActivity && acc.NAME === result[2].data()[`SUBJECT`]) {
                            // не проверяем
                        } else if (!isConflict) {
                            // проверяем на пересечение
                            isConflict =
                                visitFrom.isBetween(dateFrom, dateTo, undefined, '[]') ||
                                visitTo.isBetween(dateFrom, dateTo, undefined, '[]')
                        }

                        acc.USER_ID = userID
                        const user = userItems.find(u => u[`ID`] === userID)
                        acc.DATE_FROM = dateFrom
                        acc.DATE_TO = dateTo

                        acc.USER_NAME =
                            user === undefined
                                ? `#${userID}`
                                : `${user[`LAST_NAME`]} ${user[`NAME`]}`
                        accessibility.push(acc)
                    })
                })
                global.console.info(
                    `Занятость организатора и участников [${isConflict}]`,
                    accessibility
                )
                this.setState(
                    update(this.state, {
                        $merge: {
                            accessibility,
                            isAccessibilityVisible: isConflict,
                        },
                    })
                )
            }
        })
    }

    /**
     * Создание автоматических событий
     */
    private proceedAutoEvents(): void {
        const { item, isNew } = this.state

        if (item === null || isNew) {
            return
        }

        global.console.info('Создание автоматических событий...')
        window.BX24.callMethod(
            'authentica.visit.events.auto',
            {
                ['VISIT_ID']: this.state.id,
                ['ACTION_REFS']: ACTION_REFS,
                ['PROPERTY_SALON']: JSON.stringify({
                    COMPANY: [item.getValue('PROPERTY_SALON')],
                }),
                ['SALON_ID']: item.getValue('PROPERTY_SALON'),
                ['ACTIVE_FROM']: item.getValue('ACTIVE_FROM'),
                ['ACTIVE_TO']: item.getValue('ACTIVE_TO'),
                ['RESPONSIBLE_ID']: item.getValue('PROPERTY_RESPONSIBLE'),
            },
            (result: BX24.ResultObject) => {
                if (result.error()) {
                    global.console.error('Ошибка получения списка событий', result.error())
                } else {
                    global.console.info(`Список событий`, result.data())
                    this.loadTimeline()
                }
            }
        )
    }

    private cancelItemEdit(): void {
        if (this.state.isNew) {
            if (this.props.back === undefined) {
                window.BX24.closeApplication()
            } else {
                this.props.back()
            }
        } else {
            this.componentDidMount()
        }
    }

    /**
     * Сохраняет изменения
     */
    private saveItem(): Promise<number> {
        return new Promise((resolve, reject) => {
            const { item, isNew, newStatus } = this.state
            if (item === null) {
                reject('Элемент не определен')
            } else {
                if (item.isValid()) {
                    item.save(['PROPERTY_135', 'PREVIEW_TEXT'])
                        .then(itemId => {
                            Logger.info(`[onSave]: ${itemId}, status: ${newStatus}`)

                            this.saveLog()

                            const stateUpdate: {
                                newStatus?: string
                                id?: number
                                isNew?: boolean
                                log?: LogItem[]
                                statusEnabled?: string[]
                                isAccessibilityVisible: boolean
                            } = {
                                log: [],
                                isAccessibilityVisible: false,
                            }

                            if (isNew) {
                                stateUpdate.id = itemId
                                stateUpdate.isNew = false
                                stateUpdate.statusEnabled = []

                                window.BX24.callMethod(
                                    'authentica.visit.log.add',
                                    {
                                        visitId: itemId,
                                        params: 'Визит создан',
                                        status: newStatus,
                                    },
                                    (result: BX24.ResultObject) => {
                                        Logger.log('Запись в журнал о создании визита', result)
                                    }
                                )
                            }

                            if (newStatus !== null) {
                                stateUpdate.newStatus = newStatus
                                stateUpdate.statusEnabled = []
                                // Отправляем изменения статуса (для срабатывания обработчиков дела)
                                window.BX24.callMethod(
                                    'authentica.visit.status.set',
                                    {
                                        ELEMENT_ID: isNew ? itemId : this.state.id,
                                        STATUS_ID: newStatus,
                                        SAVE_LOG: 'N', // Отключает запись в журнал (так как основной update уже это сделал)
                                    },
                                    (result: BX24.ResultObject) => {
                                        if (result.error()) {
                                            Logger.error(
                                                `Ошибка обработки статуса #${newStatus}`,
                                                result.error()
                                            )
                                        } else {
                                            Logger.info(
                                                `Изменение статуса #${newStatus} обработано`,
                                                result.data()
                                            )
                                        }
                                    }
                                )
                            }

                            if (Object.keys(stateUpdate).length > 0) {
                                this.setState(update(this.state, { $merge: stateUpdate }), () => {
                                    if (isNew || newStatus) {
                                        window.BX24.callMethod(
                                            'authentica.visit.status.enabled',
                                            { VISIT_ID: isNew ? itemId : this.state.id },
                                            (result: BX24.ResultObject) => {
                                                if (result.error()) {
                                                    Logger.error(
                                                        `Ошибка получения доступных статусов`,
                                                        result.error()
                                                    )
                                                } else {
                                                    Logger.info(
                                                        'enabled status list',
                                                        result.data()
                                                    )
                                                    this.setState(
                                                        update(this.state, {
                                                            $merge: {
                                                                statusEnabled: result.data() as string[],
                                                            },
                                                        })
                                                    )
                                                }
                                            }
                                        )
                                    }
                                })
                            }

                            resolve(itemId)
                        })
                        .catch(error => reject(error))
                } else {
                    reject('Не заполнены все обязательные поля')
                }
            }
        })
    }

    private saveLog() {
        const { id, isNew, log } = this.state

        if (isNew || log.length <= 0) {
            return
        }

        const batch = log.map(row => [
            'authentica.visit.log.add',
            {
                visitId: id,
                params: row,
            },
        ])

        window.BX24.callBatch(batch, (result: BX24.BatchResult) => {
            Logger.log(`history saved`, result)
        })
    }

    /**
     * Может применяется для ведения истории или каких-то связанных действий,
     * например, для отбражения связанных данных (контакты/компании)
     */
    private changeField(field: string, value: string, oldValue: string): void {
        // Logger.info(`[onChange] ${field}`, value)

        const { item, log } = this.state
        if (item === null) {
            return
        }

        const logItem: LogItem = {
            name: field,
            value_new: value,
            value_old: oldValue,
        }

        const newLog = log.filter(row => {
            if (row.name === logItem.name) {
                logItem.value_old = row.value_old
                return false
            } else {
                return true
            }
        })

        newLog.push(logItem)

        switch (field) {
            case 'PROPERTY_MEMBERS':
            case 'PROPERTY_RESPONSIBLE':
                this.setState(
                    update(this.state, { changes: { $push: [value] }, $merge: { log: newLog } }),
                    this.loadAccessibilityUsers
                )
                break

            case 'PROPERTY_STATUS':
                this.setState(
                    update(this.state, {
                        $merge: { newStatus: value, log: newLog },
                        changes: { $push: [value] },
                    })
                )
                break

            case 'ACTIVE_FROM':
                // Установка дата/время окончания +1 час к началу, если не задано или ранее начала
                const dateFrom = moment(value, DATETIME_FORMAT)
                const dateTo = item.getValue('ACTIVE_TO')
                if (moment(dateTo, DATETIME_FORMAT).isBefore(dateFrom)) {
                    const activeTo = item.get('ACTIVE_TO')
                    if (activeTo !== undefined) {
                        global.console.log(activeTo)
                        activeTo.setValue([dateFrom.add(1, 'hour').format(DATETIME_FORMAT)])
                    }
                }
                this.setState(
                    update(this.state, {
                        changes: { $push: [value] },
                        $merge: { log: newLog },
                    }),
                    this.loadAccessibilityUsers
                )
                break

            // Автозаполнение контактов салона
            case 'PROPERTY_SALON':
                this.loadSalonContact(value)
                break

            // Автозаполнение салона, если выбран ответственный от салона и нет салона
            case 'PROPERTY_SALON_RESPONSIBLE':
                if (this.state.salon === undefined) {
                    window.BX24.callMethod(
                        'crm.contact.get',
                        { id: value },
                        (result: BX24.ResultObject) => {
                            if (result.error()) {
                                Logger.error('Ошибка загрузки компании', result.error())
                            } else {
                                const fieldSalon = item.get('PROPERTY_SALON')
                                const contact = result.data() as BX24.Entity.CRM.Contact
                                if (fieldSalon !== undefined) {
                                    fieldSalon.setValue([contact.COMPANY_ID])
                                }
                            }
                            this.setState(
                                update(this.state, {
                                    changes: { $push: [value] },
                                    $merge: { log: newLog },
                                })
                            )
                        }
                    )
                } else {
                    this.setState(
                        update(this.state, { changes: { $push: [value] }, $merge: { log: newLog } })
                    )
                }
                break

            default:
                this.setState(
                    update(this.state, { changes: { $push: [value] }, $merge: { log: newLog } })
                )
        }
    }

    /**
     * Обновляет таймлайн после закрытия вышестоящего слайдера
     */
    private initEventListener() {
        window.addEventListener('message', (e: MessageEvent) => {
            // tslint:disable-next-line: deprecation
            e = e || window.event

            if (e.data === undefined && e.data === null && e.data.length === 0) {
                return
            }

            const cmd = e.data.split(':')
            if (cmd.shift() !== 'CustomVisitHelper' || cmd.length < 3) {
                return
            }

            const command = cmd.shift()
            const params = JSON.parse(cmd.join(':'))

            Logger.info(`CustomVisitHelper [${command}]`, params)

            switch (command) {
                case 'onCloseSidePanel':
                    this.loadTimeline()
                    break
                default:
            }
        })
    }
}
