import * as React from 'react'

export interface CardAction {
	isEdit: boolean
	readOnly: boolean
	isValidate: boolean
	isHideEmpty: boolean
	onChange?(field: string, value: string, oldValue: string): void
	toggle(): void
}

export const CardContext = React.createContext<CardAction>({
	isEdit: false,
	isHideEmpty: false,
	isValidate: false,
	readOnly: false,
	toggle: () => {
		global.console.error('empty toggle handler')
	},
})
