import * as Logger from 'js-logger'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import VisitCard from './components/layouts/visit-card'
import VisitList from './components/layouts/visit-list'

Logger.useDefaults()

const root = document.getElementById('root') as Element

interface PlacementOptionsEx extends BX24.PlacementOptions {
	COMPANY_ID: string | null
}

if (window !== window.top) {
	window.BX24.init(() => {
		const place = window.BX24.placement.info()
		const options = place.options as PlacementOptionsEx

		global.console.log(place)

		const fitter = (bResize: boolean) => {
			setInterval(() => {
				const scrollSize = window.BX24.getScrollSize()
				if (bResize && root.clientHeight < scrollSize.scrollHeight) {
					window.BX24.resizeWindow(scrollSize.scrollWidth, root.clientHeight)
					Logger.info(`clientHeight: ${root.clientHeight}, scrollSize: ${scrollSize.scrollHeight}`)
				} else {
					window.BX24.fitWindow()
				}
			}, 1500)
		}

		// Logger.info('placement', place)

		switch (place.placement) {
			default:
				switch (options.ENTITY_ID) {
					case 'VISIT':
						const isWithTitle = options.hasOwnProperty('WITHTITLE')
						if (isWithTitle) {
							fitter(false)
						}
						document.body.className = 'crm-iframe-popup'
						ReactDOM.render(
							<VisitCard
								id={+options.ENTITY_VALUE_ID}
								isWithTitle={isWithTitle}
								companyId={options.hasOwnProperty('COMPANY_ID') ? options.COMPANY_ID : null}
							/>,
							root
						)
						break

					default:
						fitter(false)
						ReactDOM.render(
							<BrowserRouter>
								<Switch>
									<Route path="/list/" component={VisitList} />
									<Route
										path="/visit/:id/"
										render={({ match, history }) => (
											<div className="mobile">
												<VisitCard
													id={+match.params.id}
													companyId={null}
													isWithTitle={false}
													back={() => history.push('/list/')}
												/>
											</div>
										)}
									/>
									<Redirect from="/" to={`/list/`} />
								</Switch>
							</BrowserRouter>,
							root
						)
				}
		}
	})
} else {
	ReactDOM.render(<p>The application is intended only for Bitrix24 iframe</p>, root)
}
