import * as faSync from '@fortawesome/fontawesome-free-solid/faSync'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as React from 'react'
import Select from 'react-select'
import { RouteComponentProps } from 'react-router-dom'
import update from 'immutability-helper'
import * as Entity from '../views/entity'

const Header: React.FunctionComponent<{}> = props => (
    <div>
        <span className="back-button" onClick={() => window.BX24.reloadWindow()}>
            <FontAwesomeIcon icon={faSync} />
        </span>
        <span>{props.children}</span>
    </div>
)

interface VisitListState {
    items: Entity.Item[] | null
    status: number[]
    statusRef: Entity.SelectValue[]
}

export default class VisitList extends React.PureComponent<
    RouteComponentProps<{}>,
    VisitListState
> {
    public state: VisitListState = {
        items: null,
        status: [],
        statusRef: [],
    }

    public componentDidMount() {
        const iBlockCode = 'visits'
        const iBlockTypeId = 'lists'

        window.BX24.callBatch(
            {
                CURRENT_USER: ['user.current', {}],
                FIELDS: [
                    'lists.field.get',
                    {
                        IBLOCK_CODE: iBlockCode,
                        IBLOCK_TYPE_ID: iBlockTypeId,
                    },
                ],
                LIST: [
                    'lists.element.get',
                    {
                        FILTER: {
                            // * debug
                            0: {
                                LOGIC: 'OR',
                                0: { CREATED_BY: '$result[CURRENT_USER][ID]' },
                                1: { PROPERTY_RESPONSIBLE: '$result[CURRENT_USER][ID]' },
                                2: { PROPERTY_MEMBERS: '$result[CURRENT_USER][ID]' },
                            },
                            // */
                            PROPERTY_STATUS: this.state.status,
                        },
                        ELEMENT_ORDER: { ACTIVE_FROM: 'DESC' },
                        IBLOCK_CODE: iBlockCode,
                        IBLOCK_TYPE_ID: iBlockTypeId,
                    },
                ],
            },
            (result: object) => {
                if (result[`LIST`].error()) {
                    global.console.error(result[`LIST`].error())
                } else {
                    const fields = result[`FIELDS`].data()
                    const list = result[`LIST`].data()
                    const statusList = fields[`PROPERTY_142`][`DISPLAY_VALUES_FORM`]
                    this.setState(
                        update(this.state, {
                            $merge: {
                                statusRef: Object.keys(statusList).map((key: string) => {
                                    return {
                                        label: statusList[key],
                                        value: `${key}`,
                                    }
                                }),
                                items: list.map((item: object) =>
                                    Entity.Item.create(fields, item, iBlockCode, iBlockTypeId)
                                ),
                            },
                        })
                    )
                }
            }
        )
    }

    public render(): React.ReactNode {
        const { items, statusRef } = this.state
        return items === null ? (
            <Entity.Loading>Загрузка списка...</Entity.Loading>
        ) : (
            <div className="mobile">
                <Entity.Card
                    title={
                        <Header>
                            Список визитов
                            <div style={{ marginTop: '10px', fontSize: '14px' }}>
                                <Select
                                    className="react-select"
                                    value={undefined}
                                    placeholder="Любой статус"
                                    isMulti={true}
                                    isDisabled={false}
                                    isClearable={true}
                                    isSearchable={false}
                                    options={statusRef}
                                    onChange={(newValue: Entity.SelectValue[]) => {
                                        this.setState(
                                            update(this.state, {
                                                $merge: {
                                                    status: newValue.map(v => +v.value),
                                                },
                                            }),
                                            () => this.componentDidMount()
                                        )
                                    }}
                                />
                                <button
                                    className="ui-btn ui-btn-xs ui-btn-primary"
                                    onClick={() => this.props.history.push(`/visit/0/`)}
                                >
                                    Добавить визит
                                </button>
                            </div>
                        </Header>
                    }
                    readOnly={true}
                    isHideEmpty={true}
                >
                    {items.map((item, key) => {
                        return (
                            <Entity.CardContainer key={key}>
                                <Entity.CardWidget title={`№${item.getID()}`}>
                                    <Entity.Field field={item.get('ACTIVE_FROM')} />
                                    <Entity.Field field={item.get('PROPERTY_SALON')} />
                                    <Entity.Field field={item.get('PROPERTY_SALON_RESPONSIBLE')} />
                                    <Entity.Field field={item.get('PROPERTY_STATUS')} />
                                    <div className="crm-entity-widget-content-block">
                                        <button
                                            className="ui-btn ui-btn-xs ui-btn-success"
                                            onClick={() =>
                                                this.props.history.push(`/visit/${item.getID()}/`)
                                            }
                                        >
                                            Открыть
                                        </button>
                                    </div>
                                </Entity.CardWidget>
                            </Entity.CardContainer>
                        )
                    })}
                </Entity.Card>
                <button
                    className="ui-btn ui-btn-xs ui-btn-primary"
                    onClick={() => this.props.history.push(`/visit/0/`)}
                >
                    Добавить визит
                </button>
            </div>
        )
    }
}
