import * as React from 'react'
import Loading from './loading'

interface ButtonProps {
	className?: string
	waitingTitle?: string
	style?: object
	onClick(callback: () => void): void
}

interface ButtonState {
	isWaiting: boolean
}

class Button extends React.Component<ButtonProps, ButtonState> {
	public state: ButtonState = {
		isWaiting: false
	}
	
	public render() {
		const { isWaiting } = this.state
		const { waitingTitle, children, className, style } = this.props
		return (
			<button
				className={className}
				style={style}
				disabled={isWaiting}
				onClick={() => {
					this.setState({isWaiting: true}, () => {
						this.props.onClick(() => {
							this.setState({isWaiting: false})
						})
					})
				}}
			>
			{isWaiting
				? <span><Loading/> {waitingTitle === undefined ? 'Обработка запроса...' : waitingTitle}</span>
				: children
			}
			</button>
		)
	}
}

export default Button