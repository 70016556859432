import * as faSpinner from '@fortawesome/fontawesome-free-solid/faSpinner'
// @ts-ignore
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import * as React from 'react'

const Loading: React.FunctionComponent<{}> = ({ children }) => {
	return children === undefined ? (
		<FontAwesomeIcon className="loading" icon={faSpinner} spin={true} />
	) : (
		<div className="loading_wrap">
			<FontAwesomeIcon icon={faSpinner} spin={true} /> {children}
		</div>
	)
}

export default Loading
