import update from 'immutability-helper'
import * as React from 'react'
import Select, { Async } from 'react-select'

import { FieldType } from './../fieldtype'
import Loading from './../loading'

interface SelectValue {
    label: string
    value: string
}

interface CrmProps {
    field: FieldType
    isEdit: boolean
    defaultValues?: SelectValue[]
    onChange?(field: string, value: string, oldValue: string): void
}

interface EntityType {
    id: string
    label: string
    labelMulti: string
    ownerType: number
    prefix: string
    filterField: string[]
    select: string[]
}

enum FIELDS {
    ID = 'ID',
    TITLE = 'TITLE',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    HAS_EMAIL = 'HAS_EMAIL',
    HAS_PHONE = 'HAS_PHONE',
    VALUE = 'VALUE',
    POST = 'POST',
}

const TYPES: EntityType[] = [
    {
        id: 'lead',
        label: 'Лид',
        labelMulti: 'Лиды',
        ownerType: 1,
        prefix: 'L',
        filterField: ['TITLE', 'NAME', 'SECOND_NAME', 'LAST_NAME', 'COMPANY_TITLE'],
        select: ['ID', 'TITLE'],
    },
    {
        id: 'deal',
        label: 'Сделка',
        labelMulti: 'Сделки',
        ownerType: 4,
        prefix: 'D',
        filterField: ['TITLE'],
        select: ['ID', 'TITLE'],
    },
    {
        id: 'contact',
        label: 'Контакт',
        labelMulti: 'Контакты',
        ownerType: 3,
        prefix: 'C',
        filterField: ['NAME', 'SECOND_NAME', 'LAST_NAME'],
        select: ['ID', 'NAME', 'SECOND_NAME', 'LAST_NAME', 'COMPANY_ID'],
    },
    {
        id: 'company',
        label: 'Компания',
        labelMulti: 'Компании',
        ownerType: 4,
        prefix: 'CO',
        filterField: ['TITLE'],
        select: ['ID', 'TITLE'],
    },
]

interface CrmEntity {
    readonly id: string
    readonly entityId: number
    readonly entityType: EntityType
    isReady: boolean
    title: string
    description: string
    link: string
    phone: string[]
    email: string[]
}

interface CrmState {
    entityTypes: EntityType[]
    values: CrmEntity[]
    isMonotype: boolean
    isReady: boolean
    isMobile: boolean
}

interface SelectCrmResult {
    lead?: object
    contact?: object
    company?: object
    deal?: object
    quote?: object
}

export class Crm extends React.Component<CrmProps, CrmState> {
    public static getDerivedStateFromProps(
        nextProps: CrmProps,
        prevState?: CrmState
    ): CrmState | null {
        if (prevState !== undefined) {
            const prevIDs = prevState.values
                .map(entity => entity.id)
                .sort((a, b) => a.localeCompare(b))
                .join(',')
            const nextIDs = nextProps.field
                .getValue()
                .sort((a, b) => a.localeCompare(b))
                .join(',')
            if (prevIDs === nextIDs) {
                return null
            }
        }

        const types = TYPES.map(t => t.id)
        const { USER_TYPE_SETTINGS } = nextProps.field
        const _entityTypes: EntityType[] =
            USER_TYPE_SETTINGS === undefined
                ? []
                : (Object.keys(USER_TYPE_SETTINGS)
                      .filter(
                          t => types.indexOf(t.toLowerCase()) > -1 && USER_TYPE_SETTINGS[t] === 'Y'
                      )
                      .map(t => TYPES.find(type => t.toLowerCase() === type.id))
                      .filter(t => t !== undefined) as EntityType[])
        const _isMonotype = _entityTypes.length === 1

        return {
            entityTypes: _entityTypes,
            isMonotype: _isMonotype,
            isReady: false,
            isMobile: /(ipad|iphone|android|mobile|touch)/i.test(navigator.userAgent),
            values: nextProps.field
                .getValue()
                // tslint:disable-next-line:strict-type-predicates
                .filter(v => v !== null && v.length > 0)
                .map(entityID => {
                    const entity = entityID.split('_')
                    return {
                        description: '',
                        email: [],
                        entityId: _isMonotype ? +entityID : +entity[1],
                        entityType: _isMonotype
                            ? _entityTypes[0]
                            : (_entityTypes.find(t => t.prefix === entity[0]) as EntityType),
                        id: entityID,
                        isReady: false,
                        link: '',
                        phone: [],
                        title: '',
                    }
                }),
        }
    }

    constructor(props: CrmProps) {
        super(props)
        this.state = Crm.getDerivedStateFromProps(props) as CrmState
    }

    public componentDidUpdate() {
        if (!this.state.isReady) {
            this.componentDidMount()
        }
    }

    public componentDidMount() {
        const batch: object = {}
        this.state.values
            .filter(entity => entity.isReady === false)
            .forEach(entity => {
                batch[entity.id] = [`crm.${entity.entityType.id}.get`, { id: entity.entityId }]
            })
        if (Object.keys(batch).length > 0) {
            window.BX24.callBatch(batch, (batchResult: object) => {
                Object.keys(batch).forEach(id => {
                    const result: BX24.ResultObject = batchResult[id]
                    if (result.error()) {
                        global.console.error(result.error())
                        return
                    }
                    const entity = this.state.values.find(e => e.id === id)
                    if (entity !== undefined) {
                        this.parseEntity(entity, result.data())
                    }
                })
                this.setState(update(this.state, { $merge: { isReady: true } }), () => {
                    // global.console.info('Crm:componentDidMount', this.state)
                })
            })
        } else {
            this.setState(update(this.state, { $merge: { isReady: true } }))
        }
    }

    /**
     * Глючит при использовании с мобильной версией,
     * не дает все нужной информации и нет возможности ограничить выборку
     */
    public selectCRM(entityType: EntityType) {
        window.BX24.selectCRM(
            {
                entityType: [entityType.id],
                multiple: this.props.field.isMultiple(),
                value: this.state.values
                    .filter(v => v.entityType.id === entityType.id)
                    .map(v => `${v.entityType.prefix}_${v.entityId}`),
            },
            (result: SelectCrmResult) => {
                if (result.hasOwnProperty(entityType.id)) {
                    const values = this.state.values
                        .filter(v => v.entityType.id !== entityType.id)
                        .map(v =>
                            this.state.isMonotype
                                ? `${v.entityId}`
                                : `${entityType.prefix}_${v.entityId}`
                        )
                    Object.keys(result[entityType.id]).forEach(key => {
                        const entityID = result[entityType.id][key].id
                        values.push(this.state.isMonotype ? entityID.split('_')[1] : entityID)
                    })
                    this.setValue(values)
                }
            }
        )
    }

    private loadCrmValues = (
        entityType: EntityType,
        inputValue: string,
        callback: (items: SelectValue[]) => void
    ): void => {
        const entityFilter = {
            LOGIC: 'OR',
        }
        entityType.filterField.forEach((field, idx) => {
            entityFilter[`%${field}`] = inputValue
        })

        window.BX24.callMethod(
            'authentica.crm.entity.list',
            {
                entity: entityType.id,
                order: { [entityType.filterField[0]]: 'asc' },
                filter: entityFilter,
                select: entityType.select,
            },
            (result: BX24.ResultObject) => {
                callback(result.data() as SelectValue[])
            }
        )
    }

    public setValue(values: string[]) {
        const { field, onChange } = this.props
        const oldValue = field.toString()
        field.setValue(values)
        if (onChange !== undefined) {
            onChange(field.getCode(), field.toString(), oldValue)
        }
    }

    public render() {
        if (!this.state.isReady) {
            return <Loading />
        }
        return (
            <div>
                {this.state.entityTypes.map((entityType, idx) => (
                    <div
                        key={idx}
                        className={
                            this.props.isEdit
                                ? 'crm-entity-widget-content-block-clients'
                                : undefined
                        }
                    >
                        <div className="crm-entity-widget-participants-container">
                            <div>
                                {this.props.isEdit && (
                                    <div className="crm-entity-widget-content-block-title">
                                        <span className="crm-entity-widget-content-block-title-text">
                                            {this.props.field.isMultiple()
                                                ? entityType.labelMulti
                                                : entityType.label}
                                        </span>
                                    </div>
                                )}
                                {this.props.isEdit && this.props.defaultValues === undefined && (
                                    <Async
                                        className="react-select"
                                        value={this.state.values
                                            .filter(v => v.entityType.id === entityType.id)
                                            .map(entity => ({
                                                label: entity.title,
                                                value: entity.id,
                                            }))}
                                        isMulti={this.props.field.isMultiple() ? true : undefined}
                                        isClearable={this.props.field.isMultiple()}
                                        placeholder={`Введите запрос для поиска`}
                                        options={this.props.defaultValues}
                                        onChange={(newValue: SelectValue | SelectValue[]) => {
                                            const values = Array.isArray(newValue)
                                                ? newValue
                                                : [newValue]
                                            this.setValue(values.map(v => v.value))
                                        }}
                                        loadOptions={(
                                            inputValue: string,
                                            callback: (items: SelectValue[]) => void
                                        ) => {
                                            this.loadCrmValues(entityType, inputValue, callback)
                                        }}
                                    />
                                )}
                                {false && this.props.isEdit && (
                                    /* unused */ <div className="crm-entity-widget-participants-title">
                                        <div className="crm-entity-widget-clients-actions-block">
                                            <div className="crm-entity-widget-actions-btn-participants">
                                                <span className="crm-entity-widget-participants-title-text">
                                                    {this.props.field.isMultiple()
                                                        ? entityType.labelMulti
                                                        : entityType.label}
                                                </span>
                                            </div>
                                            {this.props.defaultValues === undefined && (
                                                <span
                                                    className="crm-entity-widget-actions-btn-select"
                                                    onClick={() => this.selectCRM(entityType)}
                                                >
                                                    Выбрать
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {!this.state.isMonotype && !this.props.isEdit && (
                                    <div className="crm-entity-widget-content-block-title">
                                        <span className="crm-entity-widget-content-block-title-text">
                                            {entityType.labelMulti}
                                        </span>
                                    </div>
                                )}
                                {this.props.isEdit &&
                                this.state.isMonotype &&
                                this.props.defaultValues !== undefined ? (
                                    <Select
                                        className="react-select"
                                        value={this.state.values
                                            .filter(v => v.entityType.id === entityType.id)
                                            .map(entity => ({
                                                label: entity.title,
                                                value: entity.id,
                                            }))}
                                        isMulti={this.props.field.isMultiple() ? true : undefined}
                                        isClearable={this.props.field.isMultiple()}
                                        options={this.props.defaultValues}
                                        placeholder={`Выберите ${
                                            this.props.field.isMultiple()
                                                ? entityType.labelMulti
                                                : entityType.label
                                        }`}
                                        onChange={(newValue: SelectValue | SelectValue[]) => {
                                            const values = Array.isArray(newValue)
                                                ? newValue
                                                : [newValue]
                                            this.setValue(values.map(v => v.value))
                                        }}
                                    />
                                ) : (
                                    !this.props.isEdit &&
                                    this.state.values
                                        .filter(v => v.entityType.id === entityType.id)
                                        .map(entity => (
                                            <div
                                                key={entity.id}
                                                className="crm-entity-widget-client-block"
                                            >
                                                <div className="crm-entity-widget-client-box">
                                                    <div className="crm-entity-widget-client-box-name-container">
                                                        <a
                                                            className="crm-entity-widget-client-box-name"
                                                            target="_blank"
                                                            href={entity.link}
                                                        >
                                                            {entity.title}
                                                        </a>
                                                        <div className="crm-entity-widget-client-actions-container">
                                                            {entity.phone.length > 0 && (
                                                                <a
                                                                    className="crm-entity-widget-client-action-call crm-entity-widget-client-action-available"
                                                                    target="_blank"
                                                                    href={entity.link}
                                                                />
                                                            )}
                                                            {entity.email.length > 0 && (
                                                                <a
                                                                    className="crm-entity-widget-client-action-mail crm-entity-widget-client-action-available"
                                                                    target="_blank"
                                                                    href={entity.link}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {entity.description.length > 0 && (
                                                        <div className="crm-entity-widget-client-box-position">
                                                            {entity.description}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    private getFIO(data: object) {
        const fio = ['LAST_NAME', 'NAME', 'SECOND_NAME']
            .reduce(
                (r, key) =>
                    data.hasOwnProperty(key) &&
                    data[key] !== null &&
                    data[key] !== undefined &&
                    data[key].length > 0
                        ? r + ' ' + data[key]
                        : r,
                ''
            )
            .trim()
        return fio.length > 0 ? fio : '<Без имени>'
    }

    private getFM(type: string, data: object): string[] {
        return data.hasOwnProperty(type) ? data[type].map((p: object) => p[FIELDS.VALUE]) : []
    }

    private parseEntity(entity: CrmEntity, data: object) {
        entity.link = this.state.isMobile
            ? `https://${window.BX24.getDomain()}/mobile/crm/${entity.entityType.id}/?page=view&${
                  entity.entityType.id
              }_id=${data[FIELDS.ID]}`
            : `https://${window.BX24.getDomain()}/crm/${entity.entityType.id}/details/${
                  data[FIELDS.ID]
              }/`
        switch (entity.entityType.id) {
            case 'lead':
                entity.title = `${data[FIELDS.TITLE]} (№${entity.entityId}) ${this.getFIO(data)}`
                entity.phone = this.getFM(FIELDS.PHONE, data)
                entity.email = this.getFM(FIELDS.EMAIL, data)
                break

            case 'deal':
                entity.title = `${data[FIELDS.TITLE]} (№${entity.entityId})`
                break

            case 'contact':
                entity.title = this.getFIO(data)
                entity.phone = this.getFM(FIELDS.PHONE, data)
                entity.email = this.getFM(FIELDS.EMAIL, data)
                entity.description = data[FIELDS.POST] === null ? '' : data[FIELDS.POST]
                break

            case 'company':
                entity.title = data[FIELDS.TITLE]
                entity.phone = this.getFM(FIELDS.PHONE, data)
                entity.email = this.getFM(FIELDS.EMAIL, data)
                break

            default:
        }
    }
}
